import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import TextareaAutosize from 'react-textarea-autosize';
import { format } from 'date-fns';

export default class Signature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SignatureData: {
                client_signature: {},
                assesment_staff_signature: {},
                staff_signature: {},
                physician_signature: {},
                client_name: '',
                assesment_staff: '',
                staff_name: '',
                physician_name: '',
                client_sign_uploaded_time: "",
                client_sign_uploaded_time_Assesment_Staff: "",
                client_sign_uploaded_time_Physician: "",
                client_sign_uploaded_time_Staff: "",
                uploaded_by: sessionStorage.getItem("username"),

                justification:"",
            },
            localData: {
                theInputKey1: '',
                theInputKey2: '',
                theInputKey3: '',
                theInputKey4: '',
                preview1: '',
                preview2: '',
                preview3: '',
                preview4: '',
                clickedFile: '',
                invalidImage_client: '',
                invalidImage_assessment_staff: '',
                invalidImage_staff: '',
                invalidImage_physician: '',
                saved: true
            },
            save: true,
            isSaveAndComplete: props.isSaveAndComplete,
            justificationError:"",

            form_type: this.props.form_type,
        }
    }

    dataListBody = {
        apikey: sessionStorage.getItem("__TOKEN__"),
        client_id: sessionStorage.getItem("clientId"),
        form_type: this.props.form_type,
        uploaded_by: sessionStorage.getItem("username"),
    };
    getPreview() {

        if (this.state.localData.clickedFile == "file-input1") {
            const objectUrl = URL.createObjectURL(this.state.SignatureData.client_signature);
            let localData = this.state.localData;
            localData.preview1 = objectUrl;
            this.setState({ localData: localData });
        } else if (this.state.localData.clickedFile == "file-input2") {
            const objectUrl = URL.createObjectURL(this.state.SignatureData.assesment_staff_signature)
            let localData = this.state.localData;
            localData.preview2 = objectUrl;
            this.setState({ localData: localData })
        } else if (this.state.localData.clickedFile == "file-input3") {
            const objectUrl = URL.createObjectURL(this.state.SignatureData.staff_signature)
            let localData = this.state.localData;
            localData.preview3 = objectUrl;
            this.setState({ localData: localData })
        } else if (this.state.localData.clickedFile == "file-input4") {
            const objectUrl = URL.createObjectURL(this.state.SignatureData.physician_signature)
            let localData = this.state.localData;
            localData.preview4 = objectUrl;
            this.setState({ localData: localData });
        } else {
            let localData = this.state.localData;
            localData.preview1 = undefined;
            localData.preview2 = undefined;
            localData.preview3 = undefined;
            localData.preview4 = undefined;
            this.setState({ localData: localData, });

        }
        // free memory when ever this component is unmounted
        // return () => URL.revokeObjectURL(objectUrl)

    }
    handleChange = (e) => {

        if (e.target.files.length > 0) {
            let imageFile = e.target.files[0];
            if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                let localData = this.state.localData;
                let SignatureData = this.state.SignatureData;
                if (this.state.localData.clickedFile == "file-input1") {
                    localData.invalidImage_client = "Please select valid image.";
                    SignatureData.client_sign_uploaded_time = "";
                    SignatureData.uploaded_by = "";
                } else if (this.state.localData.clickedFile == "file-input2") {
                    localData.invalidImage_assessment_staff = "Please select valid image.";
                    SignatureData.client_sign_uploaded_time_Assesment_Staff = "";
                    SignatureData.uploaded_by = "";
                } else if (this.state.localData.clickedFile == "file-input3") {
                    localData.invalidImage_staff = "Please select valid image.";
                    SignatureData.client_sign_uploaded_time_Physician = "";
                    SignatureData.uploaded_by = "";
                } else if (this.state.localData.clickedFile == "file-input4") {
                    localData.invalidImage_physician = "Please select valid image.";
                    SignatureData.client_sign_uploaded_time_Staff = "";
                    SignatureData.uploaded_by = "";
                }
                this.setState({ SignatureData: SignatureData, localData: localData });
                return false;
            } else {
                let date = format(new Date(), 'MM/dd/yyyy HH:mm:ss a');
                let Uploaded_by = " by " + sessionStorage.getItem("username");
                
                let localData = this.state.localData;
                if (this.state.localData.clickedFile == "file-input1") {
                    let SignatureData = this.state.SignatureData;
                    SignatureData.client_signature = e.target.files[0];
                    SignatureData.client_sign_uploaded_time = date + Uploaded_by;

                    localData.invalidImage_client = "";
                    this.setState({ SignatureData: SignatureData, localData: localData }, () => this.getPreview());
                } else if (this.state.localData.clickedFile == "file-input2") {
                    let SignatureData = this.state.SignatureData;
                    SignatureData.assesment_staff_signature = e.target.files[0];
                    SignatureData.client_sign_uploaded_time_Assesment_Staff = date + Uploaded_by;
                    localData.invalidImage_assessment_staff = "";
                    this.setState({ SignatureData: SignatureData, localData: localData }, () => this.getPreview());
                } else if (this.state.localData.clickedFile == "file-input3") {
                    let SignatureData = this.state.SignatureData;
                    SignatureData.staff_signature = e.target.files[0];
                    SignatureData.client_sign_uploaded_time_Staff = date + Uploaded_by;
                    localData.invalidImage_staff = "";
                    this.setState({ SignatureData: SignatureData, localData: localData }, () => this.getPreview());
                } else if (this.state.localData.clickedFile == "file-input4") {
                    let SignatureData = this.state.SignatureData;
                    SignatureData.physician_signature = e.target.files[0];
                    SignatureData.client_sign_uploaded_time_Physician = date + Uploaded_by;
                    localData.invalidImage_physician = "";
                    this.setState({ SignatureData: SignatureData, localData: localData }, () => this.getPreview());
                }
                // }
            }
        }
        setTimeout(() => {
            this.props.childToParent(this.state.SignatureData);
        }, 500)
    }
    handleOnchange = (e) => {
        let localData = this.state.localData;
        localData.saved = false;
        this.setState({ localData: localData });

        let tempName = e.target.name;
        let SignatureData = this.state.SignatureData;

        if (e.target.name == "client_name") {
            SignatureData.client_name = e.target.value;
        } else if (e.target.name == "assesment_staff") {
            SignatureData.assesment_staff = e.target.value;
        } else if (e.target.name == "staff_name") {
            SignatureData.staff_name = e.target.value;
        } else if (e.target.name == "physician_name") {
            SignatureData.physician_name = e.target.value;
        }else if(e.target.name=="justification"){
            SignatureData.justification = e.target.value;
            this.setState({justificationError:""})
            
        }
        this.setState({ SignatureData: SignatureData })

        setTimeout(() => {
            this.props.childToParent(this.state.SignatureData);
        }, 500)

    }

    handleInputChange = (file) => {

        document.getElementById(file).click();
        let localData = this.state.localData;
        localData.clickedFile = file;
        localData.saved = false;
        this.setState({ localData: localData });

    }
    handleondelete = async (value) => {
        // debugger
        if (!this.state.isSaveAndComplete) {
            let confirm = window.confirm("Do you really want to delete Signature?");
            if (confirm) {
                if (value == "file-input1") {
                    let randomString = Math.random().toString(36);
                    let localData = this.state.localData;
                    localData.theInputKey1 = randomString;
                    localData.preview1 = "";
                    localData.saved = false;
                    let SignatureData = this.state.SignatureData;
                    SignatureData.client_signature = {};
                    SignatureData.client_sign_uploaded_time = "";
                    this.setState({ localData: localData, SignatureData: SignatureData });
                    // this.setState({ theInputKey1: randomString, client_signature: {}, preview1: "" ,client_sign_uploaded_time:"" });
                } else if (value == "file-input2") {
                    let randomString = Math.random().toString(36);
                    let localData = this.state.localData;
                    localData.theInputKey2 = randomString;
                    localData.preview2 = "";
                    localData.saved = false;
                    let SignatureData = this.state.SignatureData;
                    SignatureData.assesment_staff_signature = {};
                    SignatureData.client_sign_uploaded_time_Assesment_Staff = "";
                    this.setState({ localData: localData, SignatureData: SignatureData, });

                } else if (value == "file-input3") {
                    let randomString = Math.random().toString(36);
                    let localData = this.state.localData;
                    localData.theInputKey3 = randomString;
                    localData.preview3 = "";
                    localData.saved = false;
                    let SignatureData = this.state.SignatureData;
                    SignatureData.staff_signature = {};
                    SignatureData.client_sign_uploaded_time_Staff = "";
                    this.setState({ localData: localData, SignatureData: SignatureData, });

                } else if (value == "file-input4") {
                    let randomString = Math.random().toString(36);
                    let localData = this.state.localData;
                    localData.theInputKey4 = randomString;
                    localData.preview4 = "";
                    localData.saved = false;
                    let SignatureData = this.state.SignatureData;
                    SignatureData.physician_signature = {};
                    SignatureData.client_sign_uploaded_time_Physician = "";
                    this.setState({ localData: localData, SignatureData: SignatureData, });

                }

                let deleteItem = "";
                if (value == "file-input1") {
                    deleteItem = "client_signature_delete";
                } else if (value == "file-input2") {
                    deleteItem = "assesment_staff_signature_delete";
                } else if (value == "file-input3") {
                    deleteItem = "staff_signature_delete";
                } else if (value == "file-input4") {
                    deleteItem = "physician_signature_delete";
                }
                let final_data = {
                    dataListBody: {
                        apikey: sessionStorage.getItem("__TOKEN__"),
                        client_id: sessionStorage.getItem("clientId"),
                        form_type: this.props.form_type,
                        deleteItem: deleteItem
                    }
                }
                let res = await axios.post(
                    global.restApiURL + "customrest/getDeleteSignature",
                    JSON.stringify(final_data)
                );



            }
            else {
                return false;
            }
        }
        setTimeout(() => {
            this.props.childToParent(this.state.SignatureData);
        }, 500)
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        // debugger
        if(this.state.SignatureData.justification !=""){
            const formData = new FormData();
            formData.append("assesment_staff_signature", this.state.SignatureData.assesment_staff_signature);
            formData.append("client_signature", this.state.SignatureData.client_signature);
            formData.append("physician_signature", this.state.SignatureData.physician_signature);
            formData.append("staff_signature", this.state.SignatureData.staff_signature);
            formData.append("client_name", this.state.SignatureData.client_name);
            formData.append("assesment_staff", this.state.SignatureData.assesment_staff);
            formData.append("staff_name", this.state.SignatureData.staff_name);
            formData.append("physician_name", this.state.SignatureData.physician_name);
            formData.append("client_sign_uploaded_time", this.state.SignatureData.client_sign_uploaded_time);
            formData.append("client_sign_uploaded_time_Assesment_Staff", this.state.SignatureData.client_sign_uploaded_time_Assesment_Staff);
            formData.append("client_sign_uploaded_time_Physician", this.state.SignatureData.client_sign_uploaded_time_Physician);
            formData.append("client_sign_uploaded_time_Staff", this.state.SignatureData.client_sign_uploaded_time_Staff);
            formData.append("uploaded_by", sessionStorage.getItem("username"));
            formData.append("justification",this.state.SignatureData.justification);
            formData.append("datalistBody", JSON.stringify(this.dataListBody));
            let res = await axios.post(global.restApiURL + "customrest/saveReleaseInfo",formData);
    
            if (res.data.success == true) {
                toast.success('Data added successfully!', { toastId : ('Data added successfully!') }, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.loadData();
            }
        }else{
            this.setState({justificationError:"The client's questionnaire score was below 2 out of 6. Please provide a justification for performing the state Assessment.",})
        }



       
    }
    loadData = async (e) => {

        let final_data = { dataListBody: this.dataListBody }

        let res = await axios.post(
            global.restApiURL + "customrest/getReleaseInfo",
            JSON.stringify(final_data)
        );
        if (res.status == 200 && res.data.success == true) {
            let signData = this.state.SignatureData;
            signData.client_name = res.data.data.client_name;
            signData.assesment_staff = res.data.data.assesment_staff;
            signData.staff_name = res.data.data.staff_name;
            signData.physician_name = res.data.data.physician_name;
            signData.client_sign_uploaded_time = res.data.data.client_signature != null ? res.data.data.client_uploaded_date + " by " + res.data.data.uploaded_by : "";
            signData.client_sign_uploaded_time_Assesment_Staff = res.data.data.assesment_staff_signature != null ? res.data.data.assesment_staff_uploaded_date + " by " + res.data.data.uploaded_by : "";
            signData.client_sign_uploaded_time_Physician = res.data.data.physician_signature != null ? res.data.data.physician_uploaded_date + " by " + res.data.data.uploaded_by : "";
            signData.client_sign_uploaded_time_Staff = res.data.data.staff_signature != null ? res.data.data.staff_uploaded_date + " by " + res.data.data.uploaded_by : "";
            signData.uploaded_by = res.data.data.uploaded_by;
            signData.justification=res.data.data.justification;

            let localData = this.state.localData;
            localData.preview1 = res.data.data.client_signature;
            localData.preview2 = res.data.data.assesment_staff_signature;
            localData.preview3 = res.data.data.staff_signature;
            localData.preview4 = res.data.data.physician_signature;

            this.setState({ SignatureData: signData, localData: localData })
            setTimeout(() => {
                this.props.childToParent(this.state.SignatureData);
            }, 500)
        }


    }
    componentDidMount = async () => {
        this.loadData()
    }
    render() {
        return (
            <div className='row'>
                <div className='col-md-12'>
                    <form onSubmit={this.handleSubmit}>
                        <div className='card'>
                            <div className='container-fluid m-2'>
                                <div className='row marginBottom1'>
                                    <div className='col-md-2'>
                                        <strong>Person</strong>
                                    </div>
                                    <div className='col-md-3'>
                                        <strong>Name</strong>
                                    </div>
                                    <div className='col-md-3'>
                                        <strong>Signature</strong>
                                    </div>
                                    <div className='col-md-3'>
                                        <strong>Uploaded</strong>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-2'>
                                        <label>Client</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <input type='text' disabled name="client_name" value={this.state.SignatureData.client_name} className='form-control' onChange={this.handleOnchange} />

                                    </div>
                                    <div className='col-md-3'>
                                        <div className='row'>
                                            <div className='col-md-10'>
                                                {this.state.localData.invalidImage_client !== "" ? <span style={{ color: "red" }}>{this.state.localData.invalidImage_client}</span> :
                                                    this.state.localData.preview1 && <img src={this.state.localData.preview1} height="40px" width="80%" />
                                                }
                                            </div>
                                            <div className='col-md-1'>
                                                <span class="image-upload-signature">
                                                    <label for="file-input">
                                                        <i className='fa fa-upload' onClick={() => this.handleInputChange('file-input1')} />
                                                    </label>
                                                    <input id="file-input1" type="file" name='fileUpload' style={{ visibility: 'hidden' }} key={this.state.localData.theInputKey1 || ''} onChange={this.handleChange} />
                                                </span>
                                            </div>
                                            <div className='col-md-1'>
                                                <i className='fa fa-trash' disabled={this.props.isSaveAndComplete} onClick={() => { this.handleondelete("file-input1") }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <label> {this.state.SignatureData.client_sign_uploaded_time != "" ? this.state.SignatureData.client_sign_uploaded_time : ""} </label>

                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-2'>
                                        <label>Assesment Staff</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <input type='text' name="assesment_staff" disabled={true} value={this.state.SignatureData.assesment_staff} className='form-control' onChange={this.handleOnchange} />
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='row'>
                                            <div className='col-md-10'>

                                                {this.state.localData.invalidImage_assessment_staff !== "" ? <span style={{ color: "red" }}>{this.state.localData.invalidImage_assessment_staff}</span> :
                                                    this.state.localData.preview2 && <img src={this.state.localData.preview2} height="40px" width="80%" />
                                                }
                                            </div>
                                            <div className='col-md-1'>

                                                <label for="file-input">
                                                    <i className='fa fa-upload' disabled={this.props.isSaveAndComplete} onClick={() => this.handleInputChange('file-input2')} />
                                                </label>
                                                <input id="file-input2" style={{ visibility: 'hidden' }} key={this.state.localData.theInputKey2 || ''} type="file" onChange={this.handleChange} />

                                            </div>
                                            <div className='col-md-1'>
                                                <i className='fa fa-trash' disabled={this.props.isSaveAndComplete} onClick={() => { this.handleondelete("file-input2") }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <label> {this.state.SignatureData.client_sign_uploaded_time_Assesment_Staff != "" ? this.state.SignatureData.client_sign_uploaded_time_Assesment_Staff : ""} </label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-2'>
                                        <label> Staff</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <input type='text' name="staff_name" disabled={this.state.isSaveAndComplete} value={this.state.SignatureData.staff_name} className='form-control' onChange={this.handleOnchange} />
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='row'>
                                            <div className='col-md-10' >
                                                {this.state.localData.invalidImage_staff !== "" ? <span style={{ color: "red" }}>{this.state.localData.invalidImage_staff}</span> :
                                                    this.state.localData.preview3 && <img src={this.state.localData.preview3} height="40px" width="80%" />
                                                }
                                            </div>
                                            <div className='col-md-1'>
                                                <span class="image-upload-signature">
                                                    <label for="file-input">
                                                        <i className='fa fa-upload' disabled={this.props.isSaveAndComplete} onClick={() => this.handleInputChange('file-input3')} />
                                                    </label>
                                                    <input id="file-input3" style={{ visibility: 'hidden' }} key={this.state.localData.theInputKey3 || ''} type="file" onChange={this.handleChange} />
                                                </span>
                                            </div>
                                            <div className='col-md-1'>
                                                <i className='fa fa-trash' disabled={this.props.isSaveAndComplete} onClick={() => { this.handleondelete("file-input3") }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <label> {this.state.SignatureData.client_sign_uploaded_time_Staff != "" ? this.state.SignatureData.client_sign_uploaded_time_Staff : ""} </label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-2'>
                                        <label>Physician</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <input type='text' name="physician_name" disabled={this.state.isSaveAndComplete} value={this.state.SignatureData.physician_name} className='form-control' onChange={this.handleOnchange} />
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='row'>
                                            <div className='col-md-10'>
                                                {this.state.localData.invalidImage_physician !== "" ? <span style={{ color: "red" }}>{this.state.localData.invalidImage_physician}</span> :
                                                    this.state.localData.preview4 && <img src={this.state.localData.preview4} height="40px" width="80%" />
                                                }

                                            </div>
                                            <div className='col-md-1'>
                                                <span class="image-upload-signature">
                                                    <label for="file-input">
                                                        <i className='fa fa-upload' disabled={this.props.isSaveAndComplete} onClick={() => this.handleInputChange('file-input4')} />
                                                    </label>
                                                    <input id="file-input4" style={{ visibility: 'hidden' }} key={this.state.localData.theInputKey4 || ''} type="file" onChange={this.handleChange} />
                                                </span>
                                            </div>

                                            <div className='col-md-1'>
                                                <i className='fa fa-trash' disabled={this.state.isSaveAndComplete} onClick={() => { this.handleondelete("file-input4") }} />
                                            </div>

                                        </div>

                                    </div>
                                    <div className='col-md-3'>
                                        <label> {this.state.SignatureData.client_sign_uploaded_time_Physician != "" ? this.state.SignatureData.client_sign_uploaded_time_Physician : ""} </label>
                                    </div>

                                </div>
                                {(this.state.form_type=="Adult_Assessment" && sessionStorage.getItem("uncopeScore")< 2) || (this.state.form_type=="Adolescent_Assessment" && sessionStorage.getItem("craftScore")< 2) ? <>
                                   
                                <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='form-group'>
                                                <label>The client's questionnaire score was below 2 out of 6. Please provide a justification for performing the State Assessment.<span style={{color:"red", fontSize:"20px"}}>*</span></label>
                                                {this.state.justificationError!==null && !this.state.justificationError && this.props.justificationError && !this.state.SignatureData.justification ?<>
                                                    <TextareaAutosize className="form-control" style={{border:this.props.justificationError?.length>0?"1px solid red":''}}  value={this.state.SignatureData.justification}  name="justification" onChange={this.handleOnchange}/>
                                                    {this.props.justificationError.length> 0 && <span style={{color:"red"}}>{this.props.justificationError}</span>}
                                                </>:<>
                                                <TextareaAutosize className="form-control" style={{border:this.state.justificationError?.length>0?"1px solid red":''}}  value={this.state.SignatureData.justification}  name="justification" onChange={this.handleOnchange}/>
                                                {this.state.justificationError.length> 0 && <span style={{color:"red"}}>{this.state.justificationError}</span>}
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                
                              


                                </>:""} 
                                

                                <div className='row marginBottom1'>
                                    <div className='col-md-12'>
                                        <button disabled={this.state.isSaveAndComplete ? true : this.state.localData.saved} className='btn btn-success float-right  mr-4'><i className='fas fa-save'></i> Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div >
            </div >
        )
    }
}
