import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MenuItem, Select, FormControl, makeStyles } from "@material-ui/core";
import MinusIcon from "../../assests/images/minus.svg";
import Draggable, { DraggableCore } from "react-draggable";
import TrashIcon from "../../assests/images/trash-2.svg";
import PlusIcon from "../../assests/images/plus.svg";
import MoveIcon from "../../assests/images/move.svg";
import EmptyBarIcon from "../../assests/images/empty-bar.png";
import { VictoryStack, VictoryBar, VictoryChart, VictoryAxis } from "victory";
import BlankState from "./BlankState";
import "../../../src/App.css";

import axios from "axios";
import { createHashHistory } from "history";
import { API_URL } from "../../commonFunctions/ApiUrl";
import { toast } from "react-toastify";

const MainContainer = styled.section`
  width: 60%;
  margin-top: 20px;
  border: 1px solid #f0f0f0;
  position: relative;
  z-index: 2;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  height: ${(props) =>
    props.expand ? "400px" : props.blankState ? "400px" : "70px"};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size:17px;
`;

const HeadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* padding-top: 10px; */
  padding-left: 16px;
  align-items: center;
  height: ${(props) => (props.expand ? "70px" : "70px")};
`;

const ActionIconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;

const ActionIcon = styled.img`
  margin-right: 5px;
  cursor: pointer;
`;

const DataCategoryContainer = styled.div`
  padding-left: 80px;
  display: flex;
`;

const GenderDesc = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const GenderColor = styled.div`
  background-color: ${(props) => props.bgColor && props.bgColor};
  width: 30px;
  height: 10px;
  border-radius: 10px;
`;

const GenderTitle = styled.div`
  margin-left: 10px;
`;

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    border: "none",
    fontSize: "14px",
    marginTop: "2px",
    "&::before": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  },
});

const CharContainer = styled.div`
  height: 280px;
  width: 1050px;

`;

const LastContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddToDashBoard = styled.button`
  color: #7bb731;
  margin-right: 10px;
  border: none;
  background: none;
  outline: none;
  font-size:17px;
`;

// export const sampleData = [
//   {
//     name: "Assessments Completed",
//     data: [
//       { x: "January", val: 50},
//       { x: "February", val: 2 },
//       { x: "March", val: 300 },
//       { x: "April", val: 4 },
//       { x: "May", val: 50 },
//       { x: "June", val: 6 },
//       { x: "July", val: 70 },
//       { x: "August", val: 80 },
//       { x: "September", val: 90 },
//       { x: "October", val: 100 },
//       { x: "November", val: 110 },
//       { x: "December", val: 120 },
//     ],
//   },
//   {
//     name: "Updates Completed",
//     data: [
//       { x: "January", val: 100 },
//       { x: "February", val: 200 },
//       { x: "March", val: 500 },
//       { x: "April", val: 300 },
//       { x: "May", val: 150 },
//       { x: "June", val: 300 },
//       { x: "July", val: 200 },
//       { x: "August", val: 100 },
//       { x: "September", val: 400 },
//       { x: "October", val: 120 },
//       { x: "November", val: 350 },
//       { x: "December", val: 250 },
//     ],
//   } 
  
// ];

const yRange = [0, 200, 400, 600, 800, 1000, 1200];
// const genderColors = ["#A9C3EA", "#B3DAD1", "#CFE8E1", "#D9F3F5"];
const genderColors = ["#1c6ce6", "#89BC3B", "#9afce1", "#28ABE4"];
function OutcomesStackBar(props) {
  const [blankState, setBlankState] = useState(false);
  const [expand, setExpand] = useState(true);
  const [xpos, setXpos] = useState(0);
  const [age, setAge] = React.useState("2021");
  const [getOutcomesStackBar, setOutcomesStackBar] = useState([]);
  // change const  when api call
  // const getOutcomesStackBar = sampleData;
  //const stackBar = sessionStorage.getItem('stackBar');

  // const OrgId = sessionStorage.getItem("organization_id");
     const OrgId = 5184;

  useEffect((age) => {
    fetchCliebtSatckbar(age);
  }, []);

  const fetchCliebtSatckbar = (age) => {
    if (age == undefined) {
      age = "2021";
    } else {
      age = age;
    }
    axios
      .get(API_URL + `outcomes_by_year?Token=${sessionStorage.getItem('__TOKEN__')}&year=${age}&OrgId=${OrgId}`)  
      .then((response) => {
        console.log(response);
        setOutcomesStackBar(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const { addToDashBoard } = props;
  const [final_AddToDashBoard,setfinal_AddToDashBoard]=useState(addToDashBoard);

  const classes = useStyles(props);
  const handleChange = (event) => {
    var clientYear = event.target.value;
    setAge(event.target.value);
    axios
      .get(API_URL + `outcomes_by_year?Token=${sessionStorage.getItem('__TOKEN__')}&year=${clientYear}&OrgId=${OrgId}`)
      .then((response) => {
        setOutcomesStackBar(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleExpand = (e) => setExpand(!expand);
  const handleEmptyState = (e) => {
    setBlankState(true);
    sessionStorage.removeItem("OutcomeStackBar");
    setfinal_AddToDashBoard(true);
  };

  function handleDrag(e, data) {
    if (data.x < -100) {
      props.setDirection(true);
    } else if (data.x > 100) {
      props.setDirection(false);
    } else {
      setXpos(0);
    }
  }

  function stackbar() {
    sessionStorage.setItem("OutcomeStackBar", true);
    // window.location.href = "/chart";
    toast.success("Added dashlets to the dashboard successfully");
    setfinal_AddToDashBoard(false);
  }

  return (
    <Draggable
      handle="#drag"
      axis="x"
      bounds="parent"
      onDrag={handleDrag}
      allowAnyClick={false}
      position={{ x: xpos, y: 0 }}
    >
      <MainContainer expand={expand} blankState={blankState}>
        {blankState ? (
          <BlankState emptyIcon={EmptyBarIcon} />
        ) : (
          <>
            <HeadContainer expand={expand}>
              <Title>
                Outcomes - Year
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  className={classes.root}
                  style={{ marginLeft: "5px" }}
                >
                  <Select
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className={classes.root}
                    style={{width:'65px',fontSize:"17px",color:" #675757"}}
                  >
                    <MenuItem value="2022">2022</MenuItem>
                    <MenuItem value="2021">2021</MenuItem>
                    <MenuItem value="2020">2020</MenuItem>
                    <MenuItem value="2019">2019</MenuItem>
                    <MenuItem value="2018">2018</MenuItem>
                  </Select>
                </FormControl>
              </Title>
              {final_AddToDashBoard ? (
                <AddToDashBoard onClick={stackbar}>
                  + Add to Dashboard
                </AddToDashBoard>
              ) : (
                <ActionIconContainer>
                  <ActionIcon
                    src={expand ? MinusIcon : PlusIcon}
                    onClick={handleExpand}
                  />
                  <ActionIcon src={MoveIcon} id="drag" />

                  <ActionIcon src={TrashIcon} onClick={handleEmptyState} />
                </ActionIconContainer>
              )}
            </HeadContainer>
            {expand && (
              <>
                <DataCategoryContainer>
                  {getOutcomesStackBar.map((data, index) => {
                    return (
                      <GenderDesc>
                        <GenderColor
                          bgColor={genderColors[index]}
                        ></GenderColor>
                        <GenderTitle>{data.name}</GenderTitle>
                      </GenderDesc>
                    );
                  })}
                </DataCategoryContainer>
                <CharContainer>
                  <VictoryChart
                    height={300}
                    width={900}
                    domainPadding={{ x: 20, y: 0 }}
                    style={{
                      parent: {
                        padding: "0",
                        margin: "0",
                        paddingRight:"120px",
                      },
                    
                     
                    }}
                  >
                    <VictoryStack
                      // colorScale={["#A9C3EA", "#B3DAD1", "#CFE8E1", "#D9F3F5"]}
                      colorScale={["#1c6ce6", "#89BC3B", "#CFE8E1", "#D9F3F5"]}
                     
                    >
                      {getOutcomesStackBar.map((data) => {
                        console.log(getOutcomesStackBar);
                        return <VictoryBar data={data.data} barWidth={50} y="val" />;
                      })}
                    </VictoryStack>
                    <VictoryAxis
                      dependentAxis
                      tickFormat={yRange}
                      width={1050}
                      
                      style={{ tickLabels: { fontSize: "15px", padding: "0" } }}
                    />
                    <VictoryAxis style={{ tickLabels: { fontSize: "15px" } }} />
                  </VictoryChart>
                </CharContainer>
                <LastContainer style={{ fontSize: "15px"  }}>Outcomes By Month</LastContainer>
              </>
            )}
          </>
        )}
      </MainContainer>
    </Draggable>
  );
}

export default OutcomesStackBar;
