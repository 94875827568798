import React, { Component } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead';
import Select from "react-select";
import services from '../services';
import Modal from './Modals/Modal';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextField from '@material-ui/core/TextField';
import { format } from 'date-fns';
import { connect } from "react-redux"
import TextareaAutosize from 'react-textarea-autosize';

const MultiSelectOption = [
    { value: "agitated", label: "Agitated (fidget, pace, etc.)" },
    { value: "anxiety", label: "Anxiety" },
    { value: "diarrhea", label: "Diarrhea" },
    { value: "fatigue", label: "Fatigue" },
    { value: "feeling_sad", label: "Feeling sad, tense, or angry" },
    { value: "fever", label: "Fever" },
    { value: "hand_tremors", label: "Hand Tremors" },
    { value: "increased_appetite", label: "Increased appetite" },
    { value: "insomnia", label: "Insomnia or Hypersomnia" },
    { value: "memory_loss", label: "Memory Loss" },
    { value: "move_and_talk", label: "Move and talk slower than usual" },
    { value: "muscle_aches", label: "Muscle aches" },
    { value: "nausea", label: "Nausea/Vomiting" },
    { value: "runny_nose", label: "Runny nose/watery eyes" },
    { value: "see_feel", label: "See, feel, hear things that aren't there" },
    { value: "seizures", label: "Seizures" },
    { value: "sweating", label: "Sweating or heart racing" },
    { value: "vivid", label: "Vivid, unpleasant dreams" },
    { value: "yawning", label: "Yawning" },
];

class SubstanceUseBackground extends Component {
    constructor(props) {
        super(props);
        this.state = {
            substances: [{
                class_of_substance: '',
                specific_substance: '',
                problem_type: '',
                route_of_administration: '',
                other_route_of_administration: '',
                age_of_1st_use: '',
                date_last_used: '',
                how_long_used_days:'',
                how_long_used_months:'',
                how_long_used:'',
                frequency_of_use: '',
                age_of_regular_use: '',
                // periods_of_abstinence: '',
                periods_of_abstinence_days: '',
                knownAbstinence:false,
                periods_of_abstinence_months: '',
                periods_of_abstinence_years: '',
                amount_of_regular_use: '',
                rank_order: "0",
                specific_substance_options: [],
            },
            ],
            otherRoute: false,
            deleteSubstance: false,
            change: false,
            substance_comments: '',
            // New Field Added
            withdrawl_symptoms: "",
            old_symptoms: [],
            new_symptoms: "",
            new_symptoms_explain: "",
            Larger_amount_of_aod: "",
            Larger_amount_aod_explain: "",
            previous_effort_to_control_aod: "",
            previous_effort_to_cut_down_explain: "",


            Class_of_Substances_Options: [],
            problem_type_options: [],
            form_type: props.form_type,
            dataListBody: {
                apikey: sessionStorage.getItem("__TOKEN__"),
                client_id: sessionStorage.getItem("clientId"),
                form_type: props.form_type,
            },
            Saved: true,
            isSaveAndComplete: false,
            defaultSpecificSubstanceOptions: '',


        }
    }

    dropdownOptionFromZeroto100 = Array(100).fill(0)
    
    addRows = (e) => {
        e.preventDefault()
        const existingRankOrders = this.state.substances.map(substance => parseInt(substance.rank_order));
        const maxRankOrder = Math.max(...existingRankOrders);
        const newRankOrder = maxRankOrder + 1;
        const data = {
            class_of_substance: '',
            specific_substance: '',
            problem_type: '',
            route_of_administration: '',
            other_route_of_administration: '',
            age_of_1st_use: '',
            date_last_used: '',
            how_long_used_days:'',
            how_long_used_months:'',
            how_long_used:'',
            frequency_of_use: '',
            age_of_regular_use: '',
            // periods_of_abstinence: '',
            knownAbstinence:false,
            periods_of_abstinence_days: '',
            periods_of_abstinence_months: '',
            periods_of_abstinence_years: '',

            amount_of_regular_use: '',
            rank_order: newRankOrder.toString(),
            specific_substance_options: this.state.defaultSpecificSubstanceOptions,
        }
        this.setState({ substances: [...this.state.substances, data] });
    }
    deleteRow = (e) => {
        // alert("hii");

        let rows = this.state.substances;
        rows.splice([parseInt(e.target.id)], 1);
        this.setState({ substances: rows, deleteSubstance: false });
    }

    handleChange = async (e) => {
        const name = e.target.name, value = e.target.value, index = e.target.index;

        this.setState({ Saved: false });
        if (name === "date_last_used") {
            const today = new Date();
            const inputDate = new Date(value);
            if (inputDate > today) {
                alert("You cannot enter future date in this field.");
                return;
            }
        }
        if (e.target.name == "other") {
            this.setState({ otherRoute: true });
        } else if (e.target.name == "substance_comments") {
            this.setState({ substance_comments: e.target.value });
        } else if (e.target.name == "withdrawl_symptoms") {
            this.setState({ withdrawl_symptoms: e.target.value });
            e.target.value == "No" && this.setState({ old_symptoms: [] });

        } else if (e.target.name == "new_symptoms") {
            this.setState({ new_symptoms: e.target.value });
            e.target.value =="No" && this.setState({new_symptoms_explain:""})
        } else if (e.target.name == "new_symptoms_explain") {
            this.setState({ new_symptoms_explain: e.target.value });
        } else if (e.target.name == "Larger_amount_of_aod") {
            this.setState({ Larger_amount_of_aod: e.target.value });
            e.target.value == "No" && this.setState({ Larger_amount_aod_explain: "" })
        } else if (e.target.name == "Larger_amount_aod_explain") {
            this.setState({ Larger_amount_aod_explain: e.target.value });
        } else if (e.target.name == "previous_effort_to_control_aod") {
            this.setState({ previous_effort_to_control_aod: e.target.value });
            e.target.value == "No" && this.setState({ previous_effort_to_cut_down_explain: "" });
        } else if (e.target.name == "previous_effort_to_cut_down_explain") {
            this.setState({ previous_effort_to_cut_down_explain: e.target.value });
        }
        else if (e.target.name == "problem_type") {
            // if (e.target.value == "") {
            // this.setState({ change: true });
            // let substances = this.state.substances;
            // substances[e.target.id]["specific_substance"] = e.target.value;
            // substances[e.target.id]["problem_type"] = e.target.value;
            // this.setState({ substances });

            // } else {
            this.setState({ change: true });
            let substances = this.state.substances;
            substances[e.target.id][e.target.name] = e.target.value;
            if (e.target.value == "") {
                substances[e.target.id]["specific_substance"] = "";
            }
            this.setState({ substances });
            var id = e.target.id;
            let final_data = { dataListBody: this.dataListBody, problem_type: e.target.value }
            let res = await axios.post(global.restApiURL + "customRest/getSubstanceProblemType", JSON.stringify(final_data));
            if (res.status == 200 && res.data.success == true) {
                let xyz = this.state.substances;
                xyz[id].specific_substance_options = res.data.data.problem_type.substance;
                xyz[id].class_of_substance = res.data.data.problem_type.substanceClass;
                this.setState({ substances: xyz });
            }

            // }

        }

        else if (e.target.name == "specific_substance") {
            let tempval = e.target.value;
            this.setState({ change: true });
            let substances = this.state.substances;
            substances[e.target.id][e.target.name] = e.target.value;
            this.setState({ substances });
            var id = e.target.id;
            let final_data = { dataListBody: this.dataListBody, problem_substance_type: e.target.value }
            let res = await axios.post(
                global.restApiURL + "customRest/getProblemSubstanceType",
                JSON.stringify(final_data)
            );
            if (res.status == 200 && res.data.success == true) {
                let xyz = this.state.substances;
                xyz[id].problem_type = res.data.data.substance_problem_type.problem;
                xyz[id].class_of_substance = res.data.data.substance_problem_type.substanceClass;
                this.setState({ substances: xyz });
            }


            if (tempval == "") {
                let xyz = this.state.substances;
                xyz[id].specific_substance_options = this.state.defaultSpecificSubstanceOptions;
                this.setState({ substances: xyz });
            }
        } 
        else if (e.target.name == "rank_order") {
            this.setState({ change: true });
            if (e.target.value < 100) {
                let substances = this.state.substances;
                substances[e.target.id][e.target.name] = e.target.value;
                this.setState({ substances: substances });
            }
        }
        // New code Added start
        else if (e.target.name == "periods_of_abstinence_months") {
            this.setState({ change: true });
            if (e.target.value < 13 && e.target.value >= 0) {
                let substances = this.state.substances;
                substances[e.target.id][e.target.name] = e.target.value;
                this.setState({ substances: substances });
            }
        }else if (e.target.name == "periods_of_abstinence_days") {
            this.setState({ change: true });
            if (e.target.value < 32 && e.target.value >= 0) {
                let substances = this.state.substances;
                substances[e.target.id][e.target.name] = e.target.value;
                this.setState({ substances: substances });
            }
        }else if (e.target.name === "knownAbstinence") {
            this.setState({ change: true });
            const isChecked = e.target.checked; // Get the current checked state
            let substances = this.state.substances;
            substances[e.target.id][e.target.name] = isChecked; // Update the state based on checked state
            this.setState({ substances: substances });
        }else if (e.target.name == "how_long_used_months") {
            this.setState({ change: true });
            if (e.target.value < 13 && e.target.value >= 0) {
                let substances = this.state.substances;
                substances[e.target.id][e.target.name] = e.target.value;
                this.setState({ substances: substances });
            }
        }else if (e.target.name == "how_long_used_days") {
            this.setState({ change: true });
            if (e.target.value < 32 && e.target.value >= 0) {
                let substances = this.state.substances;
                substances[e.target.id][e.target.name] = e.target.value;
                this.setState({ substances: substances });
            }
        }
        else {
            this.setState({ change: true });
            let substances = this.state.substances;
            substances[e.target.id][e.target.name] = e.target.value;
            this.setState({ substances: substances });
        }
        setTimeout(() => {
            this.props.childToParent(
                this.state.substances,
                this.state.substance_comments,
                this.state.withdrawl_symptoms,
                this.state.old_symptoms,
                this.state.new_symptoms,
                this.state.new_symptoms_explain,
                this.state.Larger_amount_of_aod,
                this.state.Larger_amount_aod_explain,
                this.state.previous_effort_to_control_aod,
                this.state.previous_effort_to_cut_down_explain
            )
        }, 500)
        if (name == "specific_substance" || name == "route_of_administration" || name == "age_of_1st_use" || name == "frequency_of_use") {
            setTimeout(() => {
                this.props.DataManageforSubDisrder(this.state.substances, index, name, value, false)
            }, 500)
        }
    }

    // handleTypeAhead = (index, option) => {
    //     this.setState({ change: true });
    //     let substances = this.state.substances;
    //     let key = Object.keys(option[0])[0];
    //     let value = Object.values(option[0])[0];
    //     substances[index][key] = value;
    // }

    handleMultiselect = (e) => {
        this.setState({ old_symptoms: e })
        this.setState({ Saved: false });
        setTimeout(() => {
            this.props.childToParent(
                this.state.substances,
                this.state.substance_comments,
                this.state.withdrawl_symptoms,
                this.state.old_symptoms,
                this.state.new_symptoms,
                this.state.new_symptoms_explain,
                this.state.Larger_amount_of_aod,
                this.state.Larger_amount_aod_explain,
                this.state.previous_effort_to_control_aod,
                this.state.previous_effort_to_cut_down_explain,
            );
        }, 500);
    }

    deletePopUp = (e) => {
        this.setState({ deleteSubstance: true });
    }
    dataListBody = {
        apikey: sessionStorage.getItem("__TOKEN__"),
        client_id: sessionStorage.getItem("clientId"),
        form_type: this.props.form_type,
        uploaded_by: sessionStorage.getItem("username"),
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        let final_data = {
            // substances: array,
            substances: this.state.substances,
            substance_comments: this.state.substance_comments,
            withdrawl_symptoms: this.state.withdrawl_symptoms,
            old_symptoms: this.state.old_symptoms,
            new_symptoms: this.state.new_symptoms,
            new_symptoms_explain: this.state.new_symptoms_explain,
            Larger_amount_of_aod: this.state.Larger_amount_of_aod,
            Larger_amount_aod_explain: this.state.Larger_amount_aod_explain,
            previous_effort_to_control_aod: this.state.previous_effort_to_control_aod,
            previous_effort_to_cut_down_explain: this.state.previous_effort_to_cut_down_explain,


            dataListBody: this.dataListBody,


        };
        let res = await axios.post(
            global.restApiURL + "customrest/savesubstances",
            JSON.stringify(final_data)
        );
        if (res.data.success == true && res.status == 200) {
            this.setState({ Saved: true });
            toast.success('Data added successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: 'success_substanceUsebackground',
            });
            this.loadData();
        }

    }

    async loadData() {
        if (!this.props.isSaveAndComplete) {
            let final_data = { dataListBody: this.dataListBody }
            let res = await axios.post(global.restApiURL + "customRest/getSubstanceinfo", JSON.stringify(final_data));
            let classSubstance_res = await axios.post(global.restApiURL + "customRest/getSubstancesClass", JSON.stringify(final_data));
            let substanceProblemType_res = await axios.post(global.restApiURL + "customRest/getProblems", JSON.stringify(final_data));
            if (res.status == 200 && res.data.success == true) {
                this.setState({
                    substances: res.data.data.substances.map((val) => {
                        return {
                            age_of_1st_use: val.age_of_1st_use,
                            age_of_regular_use: val.age_of_regular_use,
                            amount_of_regular_use: val.amount_of_regular_use,
                            class_of_substance: val.class_of_substance,
                            date_last_used: val.date_last_used,
                            frequency_of_use: val.frequency_of_use,
                            how_long_used_days:val.how_long_used_days,
                            how_long_used_months:val.how_long_used_months,
                            how_long_used:val.how_long_used,
                            route_of_administration: val.route_of_administration,
                            // periods_of_abstinence: val.periods_of_abstinence,
                            periods_of_abstinence_days: val.periods_of_abstinence_days,
                            knownAbstinence: val.knownAbstinence,
                            periods_of_abstinence_months: val.periods_of_abstinence_months,
                            periods_of_abstinence_years: val.periods_of_abstinence_years,
                            // problem_type: (val.problem_type),
                            problem_type: val.problem_type,
                            rank_order: val.rank_order,
                            specific_substance: val.specific_substance,
                            other_route_of_administration: val.other_route_of_administration,
                            // problem_type_options: (val.problem_type_all.length > 0 ? JSON.parse(val.problem_type_all) : []),
                            specific_substance_options: val.specific_substance_options.problem_type.substance,
                            // specific_substance_options:  [],
                        }
                    })
                })

                this.setState({
                    substance_comments: res.data.data.substance_comments,
                    withdrawl_symptoms: res.data.data.withdrawl_symptoms,
                    new_symptoms: res.data.data.new_symptoms,
                    new_symptoms_explain: res.data.data.new_symptoms_explain,
                    // old_symptoms: res.data.data.old_symptoms?.length > 0 ? JSON.parse(res.data.data.old_symptoms) : [],
                    old_symptoms: res.data.data.old_symptoms?.length > 0 ? JSON.parse(res.data.data.old_symptoms) : [],
                    Larger_amount_of_aod: res.data.data.Larger_amount_of_aod,
                    Larger_amount_aod_explain: res.data.data.Larger_amount_aod_explain,
                    previous_effort_to_control_aod: res.data.data.previous_effort_to_control_aod,
                    previous_effort_to_cut_down_explain: res.data.data.previous_effort_to_cut_down_explain,

                })
                // withdrawl_symptoms:res.data.data.withdrawl_symptoms,new_symptoms:res.data.data.withdrawl_symptoms,new_symptoms_explain:res.data.data.new_symptoms_explain
            }
            if (classSubstance_res.status == 200 && classSubstance_res.data.success == true) {
                let options = classSubstance_res.data.data.substanceClass.length > 0 ? JSON.parse(classSubstance_res.data.data.substanceClass) : []
                this.setState({ Class_of_Substances_Options: options })
            }
            if (substanceProblemType_res.status == 200 && substanceProblemType_res.data.success == true) {
                let options = substanceProblemType_res.data.data.problemType.length > 0 ? JSON.parse(substanceProblemType_res.data.data.problemType) : []
                this.setState({ problem_type_options: options })
            }
            setTimeout(() => {
                this.props.childToParent(
                    this.state.substances,
                    this.state.substance_comments,
                    this.state.withdrawl_symptoms,
                    this.state.old_symptoms,
                    this.state.new_symptoms,
                    this.state.new_symptoms_explain,
                    this.state.Larger_amount_of_aod,
                    this.state.Larger_amount_aod_explain,
                    this.state.previous_effort_to_control_aod,
                    this.state.previous_effort_to_cut_down_explain,
                );
            }, 500)

        } else {
            if (this.props.form_type == "Adolescent_updateAdmh" || this.props.form_type == "Adult_updateAdmh") {
                let final_data = { dataListBody: this.dataListBody }
                let res = await axios.post(global.restApiURL + "customRest/getSubstanceinfo", JSON.stringify(final_data));
                let classSubstance_res = await axios.post(global.restApiURL + "customRest/getSubstancesClass", JSON.stringify(final_data));
                let substanceProblemType_res = await axios.post(global.restApiURL + "customRest/getProblems", JSON.stringify(final_data));
                if (res.status == 200 && res.data.success == true) {
                    this.setState({
                        substances: res.data.data.substances.map((val) => {
                            return {
                                age_of_1st_use: val.age_of_1st_use,
                                age_of_regular_use: val.age_of_regular_use,
                                amount_of_regular_use: val.amount_of_regular_use,
                                class_of_substance: val.class_of_substance,
                                date_last_used: val.date_last_used,
                                frequency_of_use: val.frequency_of_use,
                                how_long_used_days:val.how_long_used_days,
                                how_long_used_months:val.how_long_used_months,
                                how_long_used:val.how_long_used,
                                route_of_administration: val.route_of_administration,
                                // periods_of_abstinence: val.periods_of_abstinence,
                                knownAbstinence: val.knownAbstinence,
                                periods_of_abstinence_days: val.periods_of_abstinence_days,
                                periods_of_abstinence_months: val.periods_of_abstinence_months,
                                periods_of_abstinence_years: val.periods_of_abstinence_years,
                                // problem_type: (val.problem_type),
                                problem_type: val.problem_type,
                                rank_order: val.rank_order,
                                specific_substance: val.specific_substance,
                                other_route_of_administration: val.other_route_of_administration,
                                // problem_type_options: (val.problem_type_all.length > 0 ? JSON.parse(val.problem_type_all) : []),
                                specific_substance_options: val.specific_substance_options.problem_type.substance,
                                // specific_substance_options:  [],


                            }
                        })
                    })
                    this.setState({
                        substance_comments: res.data.data.substance_comments,
                        isSaveAndComplete: true,
                        withdrawl_symptoms: res.data.data.withdrawl_symptoms,
                        new_symptoms: res.data.data.new_symptoms,
                        old_symptoms: res.data.data.old_symptoms?.length > 0 ? JSON.parse(res.data.data.old_symptoms) : [],
                        new_symptoms_explain: res.data.data.new_symptoms_explain,
                        Larger_amount_of_aod: res.data.data.Larger_amount_of_aod,
                        Larger_amount_aod_explain: res.data.data.Larger_amount_aod_explain,
                        previous_effort_to_control_aod: res.data.data.previous_effort_to_control_aod,
                        previous_effort_to_cut_down_explain: res.data.data.previous_effort_to_cut_down_explain,
                    })
                    // withdrawl_symptoms:res.data.data.withdrawl_symptoms,new_symptoms:res.data.data.withdrawl_symptoms,new_symptoms_explain:res.data.data.new_symptoms_explain
                }
                if (classSubstance_res.status == 200 && classSubstance_res.data.success == true) {
                    let options = classSubstance_res.data.data.substanceClass.length > 0 ? JSON.parse(classSubstance_res.data.data.substanceClass) : []
                    this.setState({ Class_of_Substances_Options: options })
                }
                if (substanceProblemType_res.status == 200 && substanceProblemType_res.data.success == true) {
                    let options = substanceProblemType_res.data.data.problemType.length > 0 ? JSON.parse(substanceProblemType_res.data.data.problemType) : []
                    this.setState({ problem_type_options: options })
                }
            } else {
                let final_data = { dataListBody: this.dataListBody }
                let res = await axios.post(global.restApiURL + "customRest/getSubstanceinfoTemp", JSON.stringify(this.dataListBody));
                let classSubstance_res = await axios.post(global.restApiURL + "customRest/getSubstancesClass", JSON.stringify(final_data));
                let substanceProblemType_res = await axios.post(global.restApiURL + "customRest/getProblems", JSON.stringify(final_data));
                if (res.status == 200 && res.data.success == true) {
                    this.setState({
                        substances: res.data.data.substances.map((val) => {
                            return {
                                age_of_1st_use: val.age_of_1st_use,
                                age_of_regular_use: val.age_of_regular_use,
                                amount_of_regular_use: val.amount_of_regular_use,
                                class_of_substance: val.class_of_substance,
                                date_last_used: val.date_last_used,
                                frequency_of_use: val.frequency_of_use,
                                how_long_used_days:val.how_long_used_days,
                                how_long_used_months:val.how_long_used_months,
                                how_long_used:val.how_long_used,
                                route_of_administration: val.route_of_administration,
                                // periods_of_abstinence: val.periods_of_abstinence,
                                periods_of_abstinence_days: val.periods_of_abstinence_days,
                                knownAbstinence: val.knownAbstinence,
                                periods_of_abstinence_months: val.periods_of_abstinence_months,
                                periods_of_abstinence_years: val.periods_of_abstinence_years,
                                // problem_type: (val.problem_type),
                                problem_type: val.problem_type,
                                rank_order: val.rank_order,
                                specific_substance: val.specific_substance,
                                other_route_of_administration: val.other_route_of_administration,
                                // problem_type_options: (val.problem_type_all.length > 0 ? JSON.parse(val.problem_type_all) : []),
                                specific_substance_options: val.specific_substance_options.problem_type.substance,
                                // specific_substance_options:  [],


                            }
                        })
                    })
                    this.setState({
                        substance_comments: res.data.data.substance_comments,
                        isSaveAndComplete: true,
                        withdrawl_symptoms: res.data.data.withdrawl_symptoms,
                        new_symptoms: res.data.data.new_symptoms,
                        new_symptoms_explain: res.data.data.new_symptoms_explain,
                        old_symptoms: res.data.data.old_symptoms?.length > 0 ? JSON.parse(res.data.data.old_symptoms) : [],
                        Larger_amount_of_aod: res.data.data.Larger_amount_of_aod,
                        Larger_amount_aod_explain: res.data.data.Larger_amount_aod_explain,
                        previous_effort_to_control_aod: res.data.data.previous_effort_to_control_aod,
                        previous_effort_to_cut_down_explain: res.data.data.previous_effort_to_cut_down_explain,
                    })
                    // withdrawl_symptoms:res.data.data.withdrawl_symptoms,new_symptoms:res.data.data.withdrawl_symptoms,new_symptoms_explain:res.data.data.new_symptoms_explain
                }
                if (classSubstance_res.status == 200 && classSubstance_res.data.success == true) {
                    let options = classSubstance_res.data.data.substanceClass.length > 0 ? JSON.parse(classSubstance_res.data.data.substanceClass) : []
                    this.setState({ Class_of_Substances_Options: options })
                }
                if (substanceProblemType_res.status == 200 && substanceProblemType_res.data.success == true) {
                    let options = substanceProblemType_res.data.data.problemType.length > 0 ? JSON.parse(substanceProblemType_res.data.data.problemType) : []
                    this.setState({ problem_type_options: options })
                }
            }
        }
    }
    async componentDidMount() {
        let final_data = { dataListBody: this.dataListBody }
        let specificSubstance_res = await axios.post(
            global.restApiURL + "customRest/getSubstances",
            JSON.stringify(final_data)
        );
        if (specificSubstance_res.status == 200 && specificSubstance_res.data.success == true) {
            let options = specificSubstance_res.data.data.substanceType.length > 0 ? JSON.parse(specificSubstance_res.data.data.substanceType) : []
            this.setState({ defaultSpecificSubstanceOptions: options })

        }
        this.loadData();
    }


    getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        // Ensure month and day have two digits
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }

        return `${year}-${month}-${day}`;
    }
    render() {
        var newRows = this.state.substances.length > 0 && this.state.substances.map((row, index) => (

            <div className='card card-default' key={index}>
                <div className='card-body'>
                    {index != 0 && <>
                        <div className='row'>
                            <div className='col-md-12'>
                                <i className='fa fa-trash float-right' id={index} onClick={this.state.change ? this.deletePopUp : this.deleteRow}></i>
                            </div>
                        </div>
                        {this.state.deleteSubstance &&
                            <Modal showCloseButton={false} className="modal-sm" showOverlay={true}>
                                <Modal.Header>
                                    <Modal.Title>
                                        <h4 className="modal-title float-left text-green">Delete Substance</h4>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Are you sure you want to delete this?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className='btn btn-success float-left' id={index} onClick={this.deleteRow}>Yes</button>
                                    <button className='btn btn-default' onClick={() => this.setState({ deleteSubstance: false })}>No</button>
                                </Modal.Footer>
                            </Modal>}
                    </>}
                    <div className='row marginBottom1'>

                        <div className='col-lg-4 col-md-6'>
                            <div className='form-group'>
                                <label>Class of Substances</label>
                                <select className='form-control' disabled={this.state.isSaveAndComplete} name="class_of_substance" value={row.class_of_substance} id={index} onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    {this.state.Class_of_Substances_Options.map((val) => {
                                        return (<option value={val.value}>{val.label}</option>)
                                    })}
                                </select>
                            </div>
                        </div>


                        <div className='col-lg-4 col-md-6'>
                            <div className='form-group'>
                                <label>Substance/Problem Type </label>
                                <select className='form-control' disabled={this.state.isSaveAndComplete} name="problem_type" value={row.problem_type} id={index} onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    {this.state.problem_type_options.map((val) => {
                                        return (<option value={val.value}>{val.label}</option>)
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6'>
                            <div className='form-group'>
                                <label>Specific Substance</label>

                                <select className='form-control' disabled={this.state.isSaveAndComplete} name="specific_substance" value={row.specific_substance} id={index} onChange={this.handleChange}>
                                    {/* specific_substance_options */}
                                    <option value="">Select</option>
                                    {row.specific_substance_options.map((val) => {
                                        return (<option value={val.value}>{val.label}</option>)
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='form-group'>
                                <label>Route of Administration</label>
                                <select className='form-control' disabled={this.state.isSaveAndComplete} name="route_of_administration" value={row.route_of_administration} id={index} onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    <option value="oral">Oral</option>
                                    <option value="smoking">Smoking</option>
                                    <option value="inhalation">Inhalation</option>
                                    <option value="injection">Injection/IV</option>
                                    <option value="injection_intramuscular">Injection/Intramuscular</option>
                                    <option value="rectal">Rectal</option>
                                    <option value="vaginal">Vaginal</option>
                                    <option value="other_specify">Other</option>
                                </select>
                            </div>
                        </div>

                        {row.route_of_administration == "other_specify" &&
                            <div className='col-6'>
                                <div className='form-group'>
                                    <label>Please Specify</label>
                                    <input type='text' disabled={this.state.isSaveAndComplete} name="other_route_of_administration" value={row.other_route_of_administration} id={index} onChange={this.handleChange} className='form-control' />
                                </div>
                            </div>
                        }
                        <div className='col-6'>
                            <div className='form-group'>
                                <label>Age of 1st Use</label>
                                <input type="text" disabled={this.state.isSaveAndComplete} className='form-control' id={index} onChange={this.handleChange} name="age_of_1st_use" value={row.age_of_1st_use} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='form-group'>
                                <label>Date Last Used</label>
                                <input type="date" disabled={this.state.isSaveAndComplete} className='form-control' id={index} onChange={this.handleChange} name="date_last_used" max={this.getCurrentDate()} value={row.date_last_used} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='form-group'>
                                <label>Frequency of Use</label>
                                <select className='form-control' disabled={this.state.isSaveAndComplete} name="frequency_of_use" id={index} onChange={this.handleChange} value={row.frequency_of_use} >
                                    <option value="">Select</option>
                                    <option value="no_use_in_the_past_month ">No use in the past month      </option>
                                    <option value="1_to_3_times_in_past_month ">1 to 3 times in past month     </option>
                                    <option value="1_to_2_times_in_the_past_week">1 to 2 times in the past week</option>
                                    <option value="3_to_6_times_in_the_past_week">3 to 6 times in the past week</option>
                                    <option value="daily">Daily</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='form-group'>
                                <label>Age of Regular Use</label>
                                <input type="text" disabled={this.state.isSaveAndComplete} className='form-control' id={index} onChange={this.handleChange} name="age_of_regular_use" value={row.age_of_regular_use} />
                            </div>
                        </div>
                        {/*  */}

                        {/*  */}
                        <div className='col-6'>
                            <div className='form-group'>
                                <label>Amount of Regular Use</label>
                                <input type="text" disabled={this.state.isSaveAndComplete} className='form-control' id={index} onChange={this.handleChange} name="amount_of_regular_use" value={row.amount_of_regular_use} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='form-group'>
                                <label>Rank Order</label>
                                <input type="number"
                                    disabled={this.state.isSaveAndComplete}
                                    className='form-control'
                                    id={index} onChange={this.handleChange}
                                    name="rank_order"
                                    value={row.rank_order}
                                />
                            </div>
                        </div>


                    </div>
                    <div className='row marginBottom1'>
                        <label className='col-12'>How long Used</label>
                        <div className='col-4'>
                            <div className='form-group'>
                                <input type="number"
                                    placeholder='Day'
                                    disabled={this.state.isSaveAndComplete}
                                    className='form-control'
                                    id={index} onChange={this.handleChange}
                                    name="how_long_used_days"
                                    value={row.how_long_used_days}
                                />
                            </div>
                        </div>
                        <div className='col-4'>
                        <div className='form-group'>
                                <input type="number"
                                    placeholder='Month'
                                    disabled={this.state.isSaveAndComplete}
                                    className='form-control'
                                    id={index} onChange={this.handleChange}
                                    name="how_long_used_months"
                                    value={row.how_long_used_months}
                                />
                            </div>
                        </div>
                        <div className='col-4'>
                        <div className='form-group'>
                                <select className='form-control' disabled={this.state.isSaveAndComplete} name="how_long_used" value={row.how_long_used} id={index} onChange={this.handleChange}>
                                    <option value="">Year</option>
                                    {this.dropdownOptionFromZeroto100.map((val, key) => {
                                        return (
                                            <option value={key}>{key}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            
                        </div>
                    </div>

                    <div className='row marginBottom1'>
                        <span  className='col-12'>
                        <label className='col-12' htmlFor={index}>Periods of Abstinence &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<span>  <input className='form-check-input' type='checkbox' id={index} name='knownAbstinence' onChange={this.handleChange}  checked={row.knownAbstinence} disabled={this.state.isSaveAndComplete}/>  Unknown </span> </label>
                     

                        </span>
                       
                  

                        
                        <div className='col-4'>
                            <div className='form-group'>
                                {/* <select className='form-control' disabled={this.state.isSaveAndComplete} name="periods_of_abstinence" value={row.periods_of_abstinence} id={index} onChange={this.handleChange}>
                                        <option value="">Select</option>
                                        {this.dropdownOptionFromZeroto100.map((val, key) => {
                                            return (
                                                <option value={key}>{key}</option>
                                            )
                                        })}&nbsp;
                                    </select> */}
                                <input type="number"
                                    placeholder='Day'
                                    disabled={this.state.isSaveAndComplete}
                                    className='form-control'
                                    id={index} onChange={this.handleChange}
                                    name="periods_of_abstinence_days"
                                    value={row.periods_of_abstinence_days}
                                />
                            </div>
                        </div>

                        <div className='col-4'>
                            <div className='form-group'>
                                {/* <select className='form-control' disabled={this.state.isSaveAndComplete} name="periods_of_abstinence" value={row.periods_of_abstinence} id={index} onChange={this.handleChange}>
                                        <option value="">Select</option>
                                        {this.dropdownOptionFromZeroto100.map((val, key) => {
                                            return (
                                                <option value={key}>{key}</option>
                                            )
                                        })}
                                    </select> */}
                                <input type="number"
                                    placeholder='Month'
                                    disabled={this.state.isSaveAndComplete}
                                    className='form-control'
                                    id={index} onChange={this.handleChange}
                                    name="periods_of_abstinence_months"
                                    value={row.periods_of_abstinence_months}
                                />

                            </div>
                        </div>

                        <div className='col-4'>
                            <div className='form-group'>
                                <select className='form-control' disabled={this.state.isSaveAndComplete} name="periods_of_abstinence_years" value={row.periods_of_abstinence_years} id={index} onChange={this.handleChange}>
                                    <option value="">Year</option>
                                    {this.dropdownOptionFromZeroto100.map((val, key) => {
                                        return (
                                            <option value={key}>{key}</option>
                                        )
                                    })}
                                </select>
                                {/* <input type="number"
                                    placeholder='Year'
                                    disabled={this.state.isSaveAndComplete}
                                    className='form-control'
                                    id={index} onChange={this.handleChange}
                                    name="periods_of_abstinence_years"
                                    value={row.periods_of_abstinence_years}
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))
        return (
            <>
                <div className="hold-transition sidebar-mini text-sm">
                    <div className="wrapper">
                        <div className="">
                            <section className="content-body">
                                <div className="row ">
                                    <div className='col-6' style={{ marginLeft: "-9px" }}>
                                        <span class="lead" style={{ fontSize: '1.05rem', lineHeight: '20px' }}>
                                            <b class="text-green">Substances </b>
                                        </span><br />
                                        <span className='float-left'>Please enter all substances ever used. </span>
                                    </div>
                                    {/* <div className='col-6'>
                                        <div className='float-right'>
                                            <button className='btn btn-sm btn-secondary' disabled={this.state.isSaveAndComplete} onClick={this.addRows}><i class="fa fa-plus mr-1"></i>Add</button>
                                        </div>
                                    </div> */}
                                </div>
                                <br />
                                <div className="row">

                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-8 col-lg-8 mb-2">
                                                Do you have a history of withdrawal symptoms when you haven't been able to obtain alcohol and or other drugs (AOD), cut down on your use, or stopped using?
                                            </div>
                                            <div className="col-md-4 col-lg-4">
                                                <select
                                                    className="form-control"
                                                    name="withdrawl_symptoms"
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isSaveAndComplete}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Yes" selected={
                                                        this.state.withdrawl_symptoms == "Yes" ? true : false
                                                    }
                                                    >
                                                        Yes
                                                    </option>
                                                    <option value="No" selected={
                                                        this.state.withdrawl_symptoms == "No" ? true : false
                                                    }
                                                    > No
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        {this.state.withdrawl_symptoms == "Yes" && <>
                                            <div className="row">
                                                <div className="col-md-6 col-lg-6 form-group">
                                                    <label>Which symptoms have you had?</label>
                                                    <Select name="old_symptoms" isMulti
                                                        options={MultiSelectOption}
                                                        closeMenuOnSelect={false}
                                                        onChange={(e) => this.handleMultiselect(e)}
                                                        value={this.state.old_symptoms}
                                                        isDisabled={this.state.isSaveAndComplete}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <label>Are you currently experiencing any of the above? </label>
                                                    <select
                                                        className="form-control"
                                                        name="new_symptoms"
                                                        onChange={this.handleChange}
                                                        disabled={this.state.isSaveAndComplete}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Yes" selected={this.state.new_symptoms == "Yes" ? true : false} >Yes </option>
                                                        <option value="No" selected={this.state.new_symptoms == "No" ? true : false} > No </option>
                                                    </select>
                                                </div>

                                                {this.state.new_symptoms ==
                                                    "Yes" && (
                                                        <>
                                                            <div className="col-md-12 col-lg-12 mb-2">
                                                            <TextareaAutosize
                                                                    minRows={(this.state.new_symptoms_explain?.length/110) + this.state.new_symptoms_explain?.split(/\r\n|\r|\n/).length}
                                                                    style={{overflow:'hidden'}}
                                                                    onChange={this.handleChange}
                                                                    value={this.state.new_symptoms_explain}
                                                                    className="form-control"
                                                                    placeholder="Please explain"
                                                                    name="new_symptoms_explain"
                                                                    disabled={this.state.isSaveAndComplete}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                            </div>
                                        </>}

                                        <div className="row">
                                            <div className="col-12 form-group">
                                                <label> Have you used larger amounts of AOD than you intended or for longer than you intended?</label>
                                                <select
                                                    className="form-control"
                                                    name="Larger_amount_of_aod"
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isSaveAndComplete}
                                                    value={this.state.Larger_amount_of_aod}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Yes"  >Yes</option>
                                                    <option value="No" > No </option>
                                                </select>
                                            </div>
                                            {this.state.Larger_amount_of_aod ==
                                                "Yes" && (
                                                    <>
                                                        <div className="col-12">
                                                        <TextareaAutosize
                                                                minRows={(this.state.Larger_amount_aod_explain?.length/110) + this.state.Larger_amount_aod_explain?.split(/\r\n|\r|\n/).length}
                                                                style={{overflow:'hidden'}}
                                                                className="form-control"
                                                                name="Larger_amount_aod_explain"
                                                                value={this.state.Larger_amount_aod_explain}
                                                                onChange={this.handleChange}
                                                                placeholder="Please explain"
                                                                disabled={this.state.isSaveAndComplete}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                        </div>

                                        <div className="row my-1">

                                            <div className="form-group col-12">
                                                <label>Have previous efforts to cut down or control AOD use been unsuccessful?</label>
                                                <select
                                                    className="form-control"
                                                    name="previous_effort_to_control_aod"
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isSaveAndComplete}
                                                    value={this.state.previous_effort_to_control_aod}
                                                >
                                                    <option value="">Select</option>
                                                    <option
                                                        value="Yes"
                                                        selected={
                                                            this.state.previous_effort_to_control_aod == "Yes" ? true : false
                                                        }
                                                    >
                                                        Yes
                                                    </option>
                                                    <option
                                                        value="No"
                                                        selected={
                                                            this.state.previous_effort_to_control_aod == "No" ? true : false
                                                        }
                                                    >
                                                        No
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        {this.state.previous_effort_to_control_aod ==
                                            "Yes" && (
                                                <>
                                                    <div className='row '>
                                                        <div className="col-12 my-1">
                                                        <TextareaAutosize
                                                                minRows={(this.state.previous_effort_to_cut_down_explain?.length/110) + this.state.previous_effort_to_cut_down_explain?.split(/\r\n|\r|\n/).length}
                                                                style={{overflow:'hidden'}}
                                                                onChange={this.handleChange}
                                                                disabled={this.state.isSaveAndComplete}
                                                                value={this.state.previous_effort_to_cut_down_explain}
                                                                className="form-control"
                                                                placeholder="Please explain"
                                                                name="previous_effort_to_cut_down_explain"

                                                            />
                                                        </div>
                                                    </div>

                                                </>
                                            )}

                                        <div className='row '>
                                            <div className='col-6'></div>
                                            <div className='col-6'>
                                                <div className='float-right my-2'>
                                                    {/* <span>*Click here to add a new class of substance &nbsp;&nbsp;&nbsp;</span> */}
                                                    <button className='btn btn-sm btn-secondary' disabled={this.state.isSaveAndComplete} onClick={this.addRows}><i class="fa fa-plus mr-1"></i>Add</button>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                {newRows}
                                            </div>



                                        </div>


                                        <div className='row marginBottom1'>

                                            <div className='col-md-12'>
                                            <TextareaAutosize 
                                                minRows={(this.state.substance_comments?.length/110) + this.state.substance_comments?.split(/\r\n|\r|\n/).length}
                                                disabled={this.state.isSaveAndComplete} 
                                                style={{overflow:'hidden'}} 
                                                name="substance_comments" 
                                                className='form-control' 
                                                value={this.state.substance_comments} 
                                                placeholder='Comments'
                                                onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <button type="submit" data-save="save" disabled={this.state.isSaveAndComplete || this.state.Saved} value='save' className="btn btn-success float-right btnmargin"> <i className="fas fa-save"></i> Save</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

// Redux connection
const mapStateToprops = (props) => {
    return {

    }
}


const mapDispatchToprops = (props) => {
    return {

    }
}

export default SubstanceUseBackground

// export default connect(mapStateToprops, mapDispatchToprops)(SubstanceUseBackground)