import React, { Component } from 'react';
import $, { data } from 'jquery';
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import Topnav from './Topnav';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
// import InputTextField from '../ReusableComponents/LabelnInput'
import { InputTextField, LabelField } from '../ReusableComponents/LabelnInput';
import { validationParser, disable_button } from '../ReusableComponents/validationParser2';
import { formObjDataMapper, formObjDataMapper2 } from '../ReusableComponents/formObjDataMapper';
// import { selectOptionsGenerator, userOptionsGenerator } from '../ReusableComponents/OptionsGenerator';
import Select from "react-select";
import ReactTooltip from 'react-tooltip';
import Tooltip from '../ReusableComponents/Tooltip';
import InputMask from 'react-input-mask';
// import Modal from 'react-modal' ;
import moment from 'moment';
import Modal from '@trendmicro/react-modal';
import DataTable, { defaultThemes } from 'react-data-table-component';
import '../global.js';
import '@trendmicro/react-modal/dist/react-modal.css';
import { classDefinitionURLGen, customURLGen, can_edit, can_view_version } from '../ReusableComponents/urlGenerator';

import { date_validate } from '../ReusableComponents/globalFunctions';

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { ConfirmBeforeLeave } from './ConfirmBeforeLeave';
import { Alert } from "./toastify";

import ViewHistoryModal from './Modals/Modal/ViewHistoryModal'
import DateTime from 'react-datetime';
import LocationSearchInput from '../Components/AddressAutocomplete';
import { encryptStorage } from './LocalStorageInterceptor';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { selectOptionsGenerator, multiSelectOptionGenerator, createMultiselectDataToSend } from '../ReusableComponents/OptionsGenerator';
// import { multiSelectOptionGenerator } from '../ReusableComponents/OptionsGenerator';
import { green } from '@material-ui/core/colors';



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

var context;
const axios = require('axios').default;

var validatorPareserobj = "";
var fundingSourceOther = "";
var now = new Date();
let initialFormValues = '';
let updatedFormValues = '';
var validationObj = {};


class Intakebasicinfo extends Component {
  constructor() {
    super();
    context = this;
    this.handleId = this.handleId.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.datamapper = this.datamapper.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.state = {
      ssnValidation:'',
      errorMessage: '',
      errorMessageSecondary: "",
      modalIsOpen: false,
      toggleSSN: true,
      object_id: 0,
      selected: {},
      viewHistoryModal: false,
      historyComparison: [],
      disablePriMsg: true,
      disableSecMsg: true,
      peerSupportOptions: [],
      viewHistoryColumns: [],
      unSaved: false,
      form_saved: false,
      min_dob: 14,
      startDateintakedate: new Date(),
      month1followupdate: new Date(now.getFullYear(), now.getMonth() + 1, now.getDate()),
      sixmonthfollow: new Date(now.getFullYear(), now.getMonth() + 6, now.getDate()),
      dob: '',
      age: '',
      viewMode: '',
      validatorPareserobj: '',
      initialClientsInfo: this.getModal(),
      intakeFormData: this.getModal(),
      required: this.getModal(),
      labels: this.getModal(),
      full_labels: this.getModal(),
      tooltip: this.getModal(),
      tooltipVisibility: this.getModal(),
      maxLength: this.getModal(),
      genderOptions: [],
      peerSupportOptions: [],
      leaveMessagePrimaryOptions: [],
      leaveMessageSecondaryOptions: [],
      lastNightStayOptions: [],
      isMailingSameOptions: [],
      stateLNSOptions: [],
      stateMailingAddressOptions: [],
      suffixOption: [],
      // New Field Added By Ashraf
      visitType: '',
      visitTypeOptions: [],
      countyOptions: [],
      state_lns_served: "",
      county_lns_served: "",
      countyServedOption: [],
      founding_resource: "",
      founding_resource_other: "",
      clientRacesMultiOptions: [],
      client_races_label: '',
      client_races_name: '',
      client_races: '',

      // hispLatHeritage : "",
      hispLatHeritageOption: [],
      // hispLatSelectOptions : "",// Added by Kuldeep singh.......................!!

      client_races_selected_op: [],
      client_import_status: false
    };
  }

  createDropdownOptions() {
    if (validatorPareserobj !== "") {
      this.setState({
        leaveMessagePrimaryOptions: selectOptionsGenerator(validatorPareserobj.options.leave_msg_pri, this.state.intakeFormData.leave_msg_pri),
        leaveMessageSecondaryOptions: selectOptionsGenerator(validatorPareserobj.options.leave_msg_sec, this.state.intakeFormData.leave_msg_sec),
        lastNightStayOptions: selectOptionsGenerator(validatorPareserobj.options.type_lns, this.state.intakeFormData.type_lns),
        stateLNSOptions: selectOptionsGenerator(validatorPareserobj.options.state_lns, this.state.intakeFormData.state_lns),
        stateMailingAddressOptions: selectOptionsGenerator(validatorPareserobj.options.state_mailing, this.state.intakeFormData.state_mailing),
        genderOptions: selectOptionsGenerator(validatorPareserobj.options.gender, this.state.intakeFormData.gender),
        suffixOption: selectOptionsGenerator(validatorPareserobj.options.suffix, this.state.intakeFormData.suffix),
        //peerSupportOptions: userOptionsGenerator(validatorPareserobj.options.peer_support_specialist , this.state.intakeFormData.peer_support_specialist),
        // New Field Added By Ashraf 129
        visitTypeOptions: selectOptionsGenerator(validatorPareserobj.options.visitType, this.state.intakeFormData.visitType),
        foundingResourceOptions: selectOptionsGenerator(validatorPareserobj.options.founding_resource, this.state.intakeFormData.founding_resource),
        // hispLatHeritageOption : selectOptionsGenerator(validatorPareserobj.options.hispLatHeritage, this.state.intakeFormData.hispLatHeritage),
      });
    }
  }


  // city_mailingstate_mailingcounty_mailingzipcode_mailingaddress_mailing


  getModal() {
    var intakeBasicInfoModal = {
      peer_support_specialist: sessionStorage.getItem('loggedinusername').trim(),
      interviewer: '',
      intake_date: '',
      dob: '',
      month_1_followup_date: '',
      month_6_followup_date: '',
      first_name: '',
      middle_initial: '',
      last_name: '',
      primary_email: '',
      secondary_email: '',
      primary_phone: '',
      leave_msg_pri: '',
      secondary_phone: '',
      leave_msg_sec: '',
      type_lns: '',
      address_lns: '',
      city_lns: '',
      state_lns: '',
      county_lns: '',
      zipcode_lns: '',
      is_mailing_same: false,
      address_mailing: '',
      city_mailing: '',
      state_mailing: '',
      county_mailing: '',
      zipcode_mailing: '',
      age: '',
      ssn: '',
      gender: '',
      organization: '',
      gpra_no: '',
      viewHistoryModal: false,
      suffix: '',
      alias: '',
      // New Field Added By Ashraf
      visitType: '',
      countyOptions: [],
      countyServedOption: [],
      state_lns_served: "",
      county_lns_served: "",
      founding_resource: "",
      founding_resource_other: "",
      client_races_label: '',
      client_races_name: '',
      client_races: '',
      client_import_status: false,
      // hispLatHeritageLabel : "",
      // hispLatHeritageOption : [],
      hispLatHeritage: "",
      // hisp_lat_heritage : ""
    };
    return intakeBasicInfoModal;
  }

  reset = (e) => {
    var stateCopy = Object.assign({}, this.state);
    stateCopy.intakeFormData['address_mailing'] = stateCopy.intakeFormData['address_lns'];
    stateCopy.intakeFormData['city_mailing'] = ""//stateCopy.intakeFormData['city_lns'];
    stateCopy.intakeFormData['state_mailing'] = stateCopy.intakeFormData['state_lns'];
    stateCopy.intakeFormData['county_mailing'] = ""//stateCopy.intakeFormData['county_lns'];
    stateCopy.intakeFormData['zipcode_mailing'] = ""//stateCopy.intakeFormData['zipcode_lns'];
    // $("#address_mailing").val('')
    // $("#city_mailing").val('')
    // $("#state_mailing").val('')
    // $("#county_mailing").val('')
    // $("#zipcode_mailing").val('')
  }

  handle_client_races = (disabling_condition, event) => {
    this.setState(prevState => ({
      ...prevState,
      client_races_selected_op: disabling_condition

    }));
  };

  handleUpdateState = async (event) => {
    const event_target_value = event.target.value;
    const event_target_name = event.target.name;
    var stateCopy = Object.assign({}, this.state);
    if (event.target.name === 'is_mailing_same') {
      stateCopy.intakeFormData[event.target.name] = event.target.checked;
      if (!event.target.checked) {
        stateCopy.intakeFormData['address_mailing'] = '';
        stateCopy.intakeFormData['city_mailing'] = '';
        stateCopy.intakeFormData['state_mailing'] = '';
        stateCopy.intakeFormData['county_mailing'] = '';
        stateCopy.intakeFormData['zipcode_mailing'] = '';
        this.setState(stateCopy);
      }
    } else {
      stateCopy.intakeFormData[event.target.name] = event.target.value;
    }
    if (sessionStorage.getItem('clientId') === '' && stateCopy.intakeFormData.state_mailing === '' && event.target.name === 'state_lns') {
      stateCopy.intakeFormData['state_mailing'] = event.target.value;
    }
    var addressFields = ["address_lns", "city_lns", "state_lns", "county_lns", "zipcode_lns"];
    if ((addressFields.indexOf(event.target.name) !== -1 && stateCopy.intakeFormData['is_mailing_same'] === true) || (event.target.name === 'is_mailing_same' && event.target.checked)) {
      this.setState(stateCopy);
      stateCopy.intakeFormData['address_mailing'] = stateCopy.intakeFormData['address_lns'];
      stateCopy.intakeFormData['city_mailing'] = stateCopy.intakeFormData['city_lns'];
      stateCopy.intakeFormData['state_mailing'] = stateCopy.intakeFormData['state_lns'];
      stateCopy.intakeFormData['county_mailing'] = stateCopy.intakeFormData['county_lns'];
      stateCopy.intakeFormData['zipcode_mailing'] = stateCopy.intakeFormData['zipcode_lns'];
      stateCopy.stateMailingAddressOptions = selectOptionsGenerator(validatorPareserobj.options.state_mailing, stateCopy.intakeFormData['state_lns']);
    }
    // Code Added By Ashraf Start
    if (event.target.name == "state_lns" || event.target.name == "state_lns_served") {
      let detaListBody = {
        apikey: sessionStorage.getItem('__TOKEN__'),
        state: event_target_value,
      }
      let county_res = await axios.post(global.restApiURL + "customRest/getCountyDropdwon", JSON.stringify(detaListBody))
      if (county_res.data.success) {
        if (event_target_name == "state_lns") {
          stateCopy.intakeFormData['state_lns'] = event_target_value;
          let tempOptions = JSON.parse(county_res.data.data).results.slice(0).sort(function (a, b) {
            var x = a.countyName.toLowerCase();
            var y = b.countyName.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          });
          stateCopy['countyOptions'] = tempOptions;
        } else if (event_target_name == "state_lns_served") {
          let tempOptions = JSON.parse(county_res.data.data).results.slice(0).sort(function (a, b) {
            var x = a.countyName.toLowerCase();
            var y = b.countyName.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          });
          stateCopy.intakeFormData['state_lns_served'] = event_target_value;
          stateCopy['countyServedOption'] = tempOptions;
        }
      }
    }
    stateCopy['unSaved'] = true;
    this.setState(stateCopy);
  }

  handleChange_gpi = address => {
    var stateCopy = Object.assign({}, this.state);
    stateCopy.intakeFormData['city_lns'] = "";//results[0].address_components[i].long_name;
    stateCopy.intakeFormData['state_lns'] = "";//results[0].address_components[i].short_name;
    stateCopy.intakeFormData['county_lns'] = "";//results[0].address_components[i].long_name;
    stateCopy.intakeFormData['zipcode_lns'] = "";//results[0].address_components[i].long_name;
    stateCopy.intakeFormData['address_lns'] = address
    stateCopy['unSaved'] = true;
    this.setState(stateCopy);
  };

  handleSelect = address => {
    this.handleChange_gpi(address)
    geocodeByAddress(address)
      // .then(results => getLatLng(results[0]))
      .then((results) => {
        var stateCopy = Object.assign({}, this.state);
        for (var i = 0; i < results[0].address_components.length; i++) {
          // stateCopy.intakeFormData['address_pri'] = results[0].formatted_address;
          if (results[0].address_components[i].types[0] === "locality") {
            stateCopy.intakeFormData['city_lns'] = results[0].address_components[i].long_name;
            stateCopy.intakeFormData['address_lns'] = stateCopy.intakeFormData['address_lns'].replace(results[0].address_components[i].long_name + ', ', '');
          }
          if (results[0].address_components[i].types[0] === "administrative_area_level_1") {
            stateCopy.intakeFormData['state_lns'] = results[0].address_components[i].short_name;
            stateCopy.intakeFormData['address_lns'] = stateCopy.intakeFormData['address_lns'].replace(results[0].address_components[i].short_name + ', ', '');
          }
          if (results[0].address_components[i].types[0] === "administrative_area_level_2") {
            stateCopy.intakeFormData['county_lns'] = results[0].address_components[i].long_name;
            stateCopy.intakeFormData['address_lns'] = stateCopy.intakeFormData['address_lns'].replace(results[0].address_components[i].long_name + ', ', '');
          }
          if (results[0].address_components[i].types[0] === "postal_code") {
            stateCopy.intakeFormData['zipcode_lns'] = results[0].address_components[i].long_name;
            stateCopy.intakeFormData['address_lns'] = stateCopy.intakeFormData['address_lns'].replace(results[0].address_components[i].long_name + ', ', '');
          }
        }
        stateCopy['unSaved'] = true;
        this.setState(stateCopy);
      })
      .catch(error => console.error('Error', error));
  };

  handleChange_gpi_mailing_address = address => {
    this.reset();
    var stateCopy = Object.assign({}, this.state);
    stateCopy.intakeFormData['address_mailing'] = address
    stateCopy['unSaved'] = true;
    this.setState(stateCopy);
  };

  handleMailingBoxUpdate = updatedValue => {
    var stateCopy = Object.assign({}, this.state);
    if (updatedValue.target.name === 'city_mailing') {
      stateCopy.intakeFormData['city_mailing'] = updatedValue.target.value;
    }
    if (updatedValue.target.name === 'county_mailing') {
      stateCopy.intakeFormData['county_mailing'] = updatedValue.target.value;
    }
    if (updatedValue.target.name === 'zipcode_mailing') {
      stateCopy.intakeFormData['zipcode_mailing'] = updatedValue.target.value;
    }
    this.setState(stateCopy);
  }

  handleSelect_mailing_address = address => {
    this.reset();
    this.handleChange_gpi_mailing_address(address)
    geocodeByAddress(address)
      .then((results) => {
        var stateCopy = Object.assign({}, this.state);
        for (var i = 0; i < results[0].address_components.length; i++) {
          if (results[0].address_components[i].types[0] === "locality") {
            stateCopy.intakeFormData['city_mailing'] = results[0].address_components[i].long_name;
            stateCopy.intakeFormData['address_mailing'] = stateCopy.intakeFormData['address_mailing'].replace(results[0].address_components[i].long_name + ', ', '');
          }
          if (results[0].address_components[i].types[0] === "administrative_area_level_1") {
            stateCopy.intakeFormData['state_mailing'] = results[0].address_components[i].short_name;
            stateCopy.intakeFormData['address_mailing'] = stateCopy.intakeFormData['address_mailing'].replace(results[0].address_components[i].short_name + ', ', '');

          }
          if (results[0].address_components[i].types[0] === "administrative_area_level_2") {
            stateCopy.intakeFormData['county_mailing'] = results[0].address_components[i].long_name;
            stateCopy.intakeFormData['address_mailing'] = stateCopy.intakeFormData['address_mailing'].replace(results[0].address_components[i].long_name + ', ', '');
          }
          if (results[0].address_components[i].types[0] === "postal_code") {
            stateCopy.intakeFormData['zipcode_mailing'] = results[0].address_components[i].long_name;
            stateCopy.intakeFormData['address_mailing'] = stateCopy.intakeFormData['address_mailing'].replace(results[0].address_components[i].long_nameFlast, '');
          }
          if (results[0].address_components[i].types[0] === "country") {
            stateCopy.intakeFormData['address_mailing'] = stateCopy.intakeFormData['address_mailing'].replace(results[0].address_components[i].long_name, '');
          }
        }
        stateCopy['unSaved'] = true;
        this.setState(stateCopy);
      })
      .catch(error => console.error('Error', error));
  };

  handleChange = date => {
    var stateCopy = Object.assign({}, this.state);
    stateCopy.intakeFormData['intake_date'] = date;
    stateCopy['unSaved'] = true;
    this.setState(stateCopy);
  };

  handlemonth1followupdate = date => {
    var stateCopy = Object.assign({}, this.state);
    stateCopy.intakeFormData['month_1_followup_date'] = date;
    stateCopy['unSaved'] = true;
    this.setState(stateCopy);
  };

  closeModal() {
    this.setState({
      modalIsOpen : false
    })
  }

  openModal() {
    this.setState({
      modalIsOpen: true
    })
  }

  handleChangesixmonthfollow = date => {
    var stateCopy = Object.assign({}, this.state);
    stateCopy.intakeFormData['month_6_followup_date'] = date;
    stateCopy['unSaved'] = true;
    this.setState(stateCopy);
  };

  handleChangedob = date => {
    var stateCopy = Object.assign({}, this.state);
    stateCopy.intakeFormData['dob'] = date;
    stateCopy.intakeFormData['age'] = this.getAge(date);
    stateCopy['unSaved'] = true;
    this.setState(stateCopy);
  };

  handleDataChange = (event) => {
    $("#saveButton").attr("disabled", false);
    $("#saveAndProceedButton").attr("disabled", false);
    $("#saveButton1").attr("disabled", false);
    $("#saveAndProceedButton1").attr("disabled", false);
    event.persist();
    var stateCopy = Object.assign({}, this.state);
    stateCopy.intakeFormData[event.target.name] = event.target.value;
    stateCopy['founding_resource_other'] = "";
    if(event.target.name == "founding_resource"){
      fundingSourceOther = "";
    }
    stateCopy['unSaved'] = true;
    stateCopy['toggleSSN'] = true;
    this.setState(stateCopy);
  }

  handleDataChange1 = (selectedOptions) => {
    let data = context.state.intakeFormData;
    data.peer_support_specialist = selectedOptions[0]?.full_name;
    this.setState({ intakeFormData: data });
  }

  getAge(dateString) {
    if (dateString === '' || dateString === null || dateString === undefined) {
      return 0;
    } else {
      var diff_ms = Date.now() - dateString.getTime();
      var age_dt = new Date(diff_ms);
      return Math.abs(age_dt.getUTCFullYear() - 1970);
    }
  }

  componentWillMount() {
    if (context.state.peerSupportOptions.length === 0) {
      let getInterviewersListBody = {
        apikey: sessionStorage.getItem('__TOKEN__'),
        organization: sessionStorage.getItem('selected_organization'),
      };
      axios.post(global.restApiURL + 'customRest/getPeerSupportSpecialist', JSON.stringify(getInterviewersListBody))
        .then(res => {
          if (res.data.success && res.status === 200) {
            // let interviewers = [];
            context.setState({
              peerSupportOptions: res.data.data,
            })
          }
          else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray['out'];
          }
          else {
            alert(res.msg);
          }
        })
        .catch(error => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          if (error.response) {
            // Request made and server responded
            alert(error.response.data.msg);
            if (error.response.status === 401 || error.response.status === 403) {
              window.location.href = global.domain + global.traversArray['out'];
            }
          } else if (error.request) {
            // The request was made but no response was received
            alert(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            alert(error.message);
          }
        })
    }
    axios.get(classDefinitionURLGen(1))
      .then(res => {
        var responce = res.data;
        validatorPareserobj = validationParser(responce.data.layoutDefinitions.childs[0].childs);
        this.setState({
          required: validatorPareserobj.mandatory,
          labels: validatorPareserobj.title,
          full_labels: validatorPareserobj.full_title,
          tooltip: validatorPareserobj.tooltip,
          tooltipVisibility: validatorPareserobj.tooltipVisibility,
          regex: validatorPareserobj.regex,
          options: validatorPareserobj.options,
          maxLength: validatorPareserobj.columnLength
        });
        this.createDropdownOptions();
      })
      .catch(error => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        if (error.response) {
          // Request made and server responded
          alert(error.response.data.msg);
          if (error.response.status === 401 || error.response.status === 403) {
            window.location.href = global.domain + global.traversArray['out'];
          }
        } else if (error.request) {
          // The request was made but no response was received
          alert(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          alert(error.message);
        }

      })
    axios.get(global.restApiURL + 'webservice/rest/class/id/9?apikey=' + sessionStorage.getItem('__CL_TOKEN__'))
      .then(res => {
        var responce = res.data.data.layoutDefinitions.childs[0].childs;
        validationObj = validationParser(responce);
        console.log(validationObj);
        this.setState({
          clientRacesMultiOptions: multiSelectOptionGenerator(validationObj.options.client_races),
          client_races_label: validationObj.title.client_races,
          client_races_name: validationObj.name.client_races,
          hispLatHeritageLabel: validationObj.title.hisp_lat_heritage,
          hispLatHeritageOption: selectOptionsGenerator(validationObj.options.hisp_lat_heritage)
        })
        // this.createSelectOptions(validationObj);
      })
      .catch(error => {
        // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(error);
      })
  }


  // createSelectOptions(validationObj) {
  //   if (validationObj !== "") {
  //     this.setState({
  //       clientRacesMultiOptions: multiSelectOptionGenerator(validationObj.options.client_races)

  //     });
  //   }
  // }

  onUnload = e => { // the method that will be used for both add and remove event
    e.preventDefault();
    e.returnValue = '';
    let updatedFormValues = $('#intakebasicinfoform').serialize();
    if (initialFormValues !== updatedFormValues) {
      this.setState({ unSaved: true });
    }
    return true;
  }

  compareFormValues = () => {
    let initialData = this.state.initialClientsInfo;
    let updatedData = this.state.intakeFormData;
    let diff = [];
    let comparisonDiff = [];
    let comparedResult = {};
    if (initialData.length > 0) {
      comparedResult = Object.keys(initialData).filter((key) => {
        let returnV = '';
        if (Array.isArray(initialData[key])) {
          if (updatedData[key].length !== initialData[key].length) {
            returnV = false;
          } else {
            let checker = (arr, target) => target.every(v => arr.includes(v));
            returnV = checker(updatedData[key], initialData[key]) && checker(initialData[key], updatedData[key])
          }
        } else {
          returnV = updatedData[key] === initialData[key]
        }
        return returnV;
      })
    }
  }

  async componentDidMount() {
    this.setState({ founding_resource_other: fundingSourceOther });
    sessionStorage.setItem('form_saved', false);
    sessionStorage.removeItem('responceId')
    var intakeInitialisedModal = this.getModal();
    intakeInitialisedModal.intake_date = new Date();
    intakeInitialisedModal.interviewer = sessionStorage.getItem('loggedinusername');
    intakeInitialisedModal.dob = '';
    this.setState({ intakeFormData: intakeInitialisedModal });
    if (sessionStorage.getItem('viewMode') === 'true') {
      this.setState({ viewMode: 'disabled' });
    }
    sessionStorage.setItem('responceId', '');

    axios.post(global.restApiURL + 'customRest/getOrgnizationFounding', JSON.stringify({
      dataListBody: {
        apikey: sessionStorage.getItem('__TOKEN__'),
        organization: sessionStorage.getItem('selected_organization'),
      }
    }))
      .then(res => {
        let fndngRsrc = JSON.parse(res.data.data[0].founding_resource);
        let dropdownOption = [];
        if (res.data.success && res.status === 200) {
          for (let i = 0; i < fndngRsrc?.length; i++) {
            dropdownOption.push(fndngRsrc[i].value);
          }
          this.setState({ founding_resource: dropdownOption });
        } else if ("error_code" in res) {
          alert(res.msg);
          window.location.href = global.domain + global.traversArray['out'];
        } else {
          alert(res.msg);
        }
      })
      .catch(error => {
        alert(error.message);
        // console.log(error.message);
      }
      )

    if (sessionStorage.getItem('clientId') !== '') {
      axios.get(global.restApiURL + 'webservice/rest/object-list?apikey=' + sessionStorage.getItem('__TOKEN__') + '&limit=1&objectClass=ClientInformation&q={"client_id":"' + sessionStorage.getItem('clientId') + '"}')
        .then(res => {
          const responce = res.data.data; // get the data array instead of object
          if (responce[0] !== undefined && responce[0]?.id !== '') {
            axios.get(global.restApiURL + 'webservice/rest/object/id/' + responce[0].id + '?apikey=' + sessionStorage.getItem('__TOKEN__'))
              .then(res => {
                sessionStorage.setItem('responceId', res.data.data.id);
                this.setState({ object_id: res.data.data.id });
                const responce = res.data.data.elements;
                this.datamapper(responce);
                let parsedClientsInfo = formObjDataMapper2(responce, this.getModal());
                this.setState({ initialClientsInfo: parsedClientsInfo });
                var rules = [];
                var regex_var = {};
                var realArray = this.state.regex;
                if (realArray !== null && realArray !== undefined) {
                  Object.entries(realArray).map(function ([field, reg_ex], i) {
                    if (reg_ex !== '' && reg_ex !== undefined) {
                      regex_var[field] = '/' + reg_ex + '/';
                      $.validator.addMethod(regex_var[field], function (value, element, parameter) {
                        return value.match(regex_var[field]);
                      }, '');
                      rules[field] = { [regex_var[field]]: true };
                    }
                  });
                }
                initialFormValues = $('#intakebasicinfoform').serialize();
              })
              .catch(error => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                if (error.response) {
                  // Request made and server responded
                  alert(error.response.data.msg);
                  if (error.response.status === 401 || error.response.status === 403) {
                    window.location.href = global.domain + global.traversArray['out'];
                  }
                } else if (error.request) {
                  // The request was made but no response was received
                  alert(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  alert(error.message);
                }
              });
          }
        })
        .catch(error => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          if (error.response) {
            // Request made and server responded
            alert(error.response.data.msg);
            if (error.response.status === 401 || error.response.status === 403) {
              window.location.href = global.domain + global.traversArray['out'];
            }
          } else if (error.request) {
            // The request was made but no response was received
            alert(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            alert(error.message);
          }
        }
        )
    }
    disable_button('intakebasicinfoform');
    //var formSubmitClick = 0;
    $.validator.setDefaults({
      submitHandler: function () {
       // if ($('#clicked').val() === 'proceed') {
       //   formSubmitClick = 0;
       // }
        //if (formSubmitClick == 0) {
          //formSubmitClick = 1;
		  $('button[type="submit"]').attr('disabled', 'disabled');
          var path = window.location.href;
          path = path.substring(global.domain.length, 50);
          var jsonData = { 'apikey': sessionStorage.getItem('__TOKEN__') };
          jsonData.data = {}
          var formData = $("#intakebasicinfoform").serializeArray();
          var client_races_set_ls = [];
          $.each(formData, function () {
            if (this.name !== "secondary_phone" && this.name !== "primary_phone" && this.name !== "ssn") {
              if (jsonData.data[this.name]) {
                if (!jsonData.data[this.name].push) {
                  jsonData.data[this.name] = [jsonData.data[this.name]];
                }
                jsonData.data[this.name].push(this.value || '');
              } else {
                jsonData.data[this.name] = this.value || '';
              }
            } else {
              if (jsonData.data[this.name]) {
                if (!jsonData.data[this.name].push) {
                  jsonData.data[this.name] = [jsonData.data[this.name]];
                }
                jsonData.data[this.name].push(this.value || '');
              }else if (this.name === "ssn") {
                jsonData.data[this.name] = this.value || '';
              }else if (this.name === "secondary_phone") {
                jsonData.data[this.name] = this.value || '';
              }else if (this.name === "primary_phone") {
                jsonData.data[this.name] = this.value || '';
              } else {
                jsonData.data[this.name] = this.value.replace(/[^A-Z0-9]/ig, "") || '';
              }
            }
            jsonData.data["is_mailing_same"] = context.state.intakeFormData.is_mailing_same;
            if (this.name == 'client_races') {
              client_races_set_ls.push(this.value);
            }
          });
          jsonData.data.client_races = client_races_set_ls;
          // sessionStorage.setItem("client_races_selected", client_races_set_ls.replace(/,(?=\s*$)/, ''));
          jsonData.data['last_updated_by'] = sessionStorage.getItem('loggedinUserID');
          jsonData.data['last_updated_on'] = moment().format('YYYY-MM-DD HH:mm');
          jsonData.data['client_status'] = 'Active';
          jsonData.data['organization'] = sessionStorage.getItem('selected_organization');
          if (sessionStorage.getItem('responceId') !== '') {
            jsonData.data['id'] = sessionStorage.getItem('responceId');
          }
          // var json = JSON.stringify(jsonData);
          axios.post(sessionStorage.getItem('client_basicinfosave_url'), JSON.stringify(jsonData))
            .then(function (res) {
              var responce = res.data;
              if (responce.client_id === undefined || responce.client_id === "") {
                sessionStorage.setItem('clientId', jsonData.data.client_id);
              } else {
                sessionStorage.setItem('clientId', responce.client_id);
				$('.saveNproceed').removeAttr('disabled');
              }
              sessionStorage.setItem('responceId', responce.id);
              encryptStorage.setItem('peer_support_specialist', jsonData.data['peer_support_specialist'])
              encryptStorage.setItem('intake_date', moment(jsonData.data['intake_date']).format('MM/DD/YYYY'));
              encryptStorage.setItem('dob', moment(jsonData.data['dob']).format('MM/DD/YYYY'))
              sessionStorage.setItem('age', $('#age').val())
              if ($('#month_1_followup_date').val() !== '') {
                encryptStorage.setItem('month_1_followup_date', date_validate(sessionStorage.getItem('last_referal_date') ? sessionStorage.getItem('last_referal_date') : encryptStorage.getItem('intake_date'), 90));
              } else {
                let month_1_followup_date = '';
                if (sessionStorage.getItem('last_referal_date')) {
                  month_1_followup_date = date_validate(sessionStorage.getItem('last_referal_date'), 1);
                } else {
                  month_1_followup_date = date_validate(encryptStorage.getItem('intake_date'), 90);
                }
                encryptStorage.setItem('month_1_followup_date', month_1_followup_date);
              }
              encryptStorage.setItem('email', jsonData.data['primary_email'])
              encryptStorage.setItem('gpra_no', jsonData.data['gpra_no']);
              encryptStorage.setItem('phone', jsonData.data['primary_phone'])
              sessionStorage.setItem('Leave_msg_pri', $('#leave_msg_pri').val())
              encryptStorage.setItem('Secondary_phone', $('#secondary_phone').val());
              sessionStorage.setItem('Leave_msg_sec', $('#leave_msg_sec').val())
              encryptStorage.setItem('last_updated_by', $('#last_updated_by').val())
              sessionStorage.setItem('formname', $('#formname').val())
              encryptStorage.setItem('client_first_name', jsonData.data['first_name'])
              encryptStorage.setItem('client_middle_initial', jsonData.data['middle_initial'])
              encryptStorage.setItem('client_last_name', jsonData.data['last_name'])
              sessionStorage.setItem('Type_lns', $('#type_lns').val());
              encryptStorage.setItem('Address_lns', $('#address_lns').val() + " " + $('#city_lns').val() + " " + $('#state_lns').val() + " " + $('#county_lns').val() + " " + $('#zipcode_lns').val());
              encryptStorage.setItem('address', $('#address_lns').val());
              encryptStorage.setItem('city_lns', $('#city_lns').val());
              encryptStorage.setItem('state_lns', $('#state_lns').val());
              encryptStorage.setItem('county_lns', $('#county_lns').val());
              encryptStorage.setItem('zipcode_lns', $('#zipcode_lns').val());
              encryptStorage.setItem('suffix', $('#suffix').val());
              encryptStorage.setItem('alias', $('#alias').val());
              sessionStorage.setItem('form_saved', true);
              // Code Added By Ashraf Start
              sessionStorage.setItem('visitType', $('#visitType').val());
              sessionStorage.setItem('founding_resource', $('#founding_resource').val());
              sessionStorage.setItem('founding_resource_other', $('#founding_resource_other').val());
              // Code Added By Ashraf End
              if ($('#clicked').val() === 'proceed') {
                sessionStorage.setItem('success_msg', "Client Information saved successfully")
                window.location.href = global.domain + global.traversArray['intake-basicinfo'];
              } else {
                if ($(".Toastify__toast-container").length == 0) {
                  Alert("success", "Client Information saved successfully.");
                  context.setState({ unSaved: false, state_saved: false });
                }
                $("#saveButton").attr("disabled", true);
              }
            })
            .catch(function (err) {
              Alert("error", err.message);
            }
          );
        }
      //}
    });


    //======================================================================= By = Kuldeep Singh ===========================================================================================================================
    axios.get(global.restApiURL + 'webservice/rest/object-list?apikey=' + sessionStorage.getItem('__TOKEN__') + '&limit=1&objectClass=Assessment&q={"client_id":"' + sessionStorage.getItem('clientId') + '"}')
      .then(res => {
        const responce = res.data.data; // get the data array instead of object
        if (responce.length !== 0) {
          sessionStorage.setItem("savedObjectId", responce[0].id);
          axios.get(global.restApiURL + 'webservice/rest/object/id/' + responce[0].id + '?apikey=' + sessionStorage.getItem('__TOKEN__'))
            .then(res => {
              const response = res.data.data.elements;
              if (res.data.data.elements[5].name == 'client_races') {
                let races;
                // for (let i = 0; i < res.data.data.elements[5].value?.length; i++) {
                  races = res.data.data.elements[5].value.map(
                    (val) => {
                      return {
                        value: val,
                        label: val
                      }
                    }
                  );
                // }
                this.setState({
                  client_races_selected_op: races
                })
              }
              if (res.data.data.elements[6].name === "hisp_lat_heritage") {
                var stateCopy = Object.assign({}, this.state);
                stateCopy.intakeFormData.hispLatHeritage = res.data.data.elements[6].value;
                this.setState({ stateCopy });
              }
            })
            .catch(err => { // log request error and prevent access to undefined state
              this.setState({ loading: false, error: true });
              console.error(err);
            })
        } else {
          // prevExist = false;
          // alert("Else condition me chala gya yar")
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      })
  }

  async datamapper(arr) {
    var now = new Date();
    var default_startDateintakedate = new Date();
    var default_month1followupdate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    var default_sixmonthfollow = new Date(now.getFullYear(), now.getMonth() + 6, now.getDate());
    var default_dob = '';// new Date(now.getFullYear()-context.state.min_dob, now.getMonth(),  now.getDate());
    let obj = {
      peer_support_specialist: '',
      interviewer: '',
      intake_date: '',
      dob: '',
      month_1_followup_date: '',
      month_6_followup_date: '',
      first_name: '',
      middle_initial: '',
      last_name: '',
      primary_email: '',
      secondary_email: '',
      primary_phone: '',
      leave_msg_pri: '',
      secondary_phone: '',
      leave_msg_sec: '',
      type_lns: '',
      address_lns: '',
      city_lns: '',
      state_lns: '',
      county_lns: '',
      zipcode_lns: '',
      is_mailing_same: '',
      address_mailing: '',
      city_mailing: '',
      state_mailing: '',
      county_mailing: '',
      zipcode_mailing: '',
      last_updated_by: '',
      gender: '',
      organization: '',
      ssn: '',
      age: '',
      suffix: '',
      alias: '',
      // New Field Added By Ashraf
      visitType: '',
      founding_resource: "",
      countyOptions: [],
      countyServedOption: [],
      state_lns_served: "",
      county_lns_served: "",
      founding_resource_other: "test123",
      client_races: '',
      client_import_status: false,
    }
    $.each(arr, function (index, value) {
      if (value.name === 'peer_support_specialist') {
        obj.peer_support_specialist = value.value;
        encryptStorage.setItem('peer_support_specialist', value.value)
      }
      if (value.name === 'interviewer') {
        obj.interviewer = value.value;
      }
      if (value.name === 'intake_date') {
        if (value.value === '') {
          obj.intake_date = default_startDateintakedate
        } else {
          obj.intake_date = new Date(value.value);
        }
        let month_1_followup_date = '';
        if (sessionStorage.getItem('last_referal_date')) {
          month_1_followup_date = date_validate(sessionStorage.getItem('last_referal_date'), 1);
        } else {
          month_1_followup_date = date_validate(encryptStorage.getItem('intake_date'), 90);
        }
        encryptStorage.setItem('month_1_followup_date', month_1_followup_date);
      }
      if (value.name === 'dob') {
        if (value.value === '') {
          obj.dob = '';
        } else {
          obj.dob = new Date(value.value);
          obj.age = context.getAge(new Date(value.value));
        }
        encryptStorage.setItem('dob', moment(value.value).format('MM/DD/YYYY'))
      }
      if (value.name === 'month_1_followup_date') {
        if (value.value === '') {
          obj.month_1_followup_date = default_month1followupdate
        } else {
          obj.month_1_followup_date = new Date(value.value);
        }
        if (value.value !== '') {
          encryptStorage.setItem('month_1_followup_date', value.value)
        } else {
          let month_1_followup_date = '';
          if (sessionStorage.getItem('last_referal_date')) {
            month_1_followup_date = date_validate(sessionStorage.getItem('last_referal_date'), 1);
          } else {
            month_1_followup_date = date_validate(encryptStorage.getItem('intake_date'), 90);
          }
          encryptStorage.setItem('month_1_followup_date', month_1_followup_date);
        }
      }
      if (value.name === 'month_6_followup_date') {
        if (value.value === '') {
          obj.month_6_followup_date = default_sixmonthfollow
        } else {
          obj.month_6_followup_date = new Date(value.value);
        }
      }
      if (value.name === 'suffix') {
        obj.suffix = value.value;
        encryptStorage.setItem('suffix', value.value)
      }
      if (value.name === 'alias') {
        obj.alias = value.value;
        encryptStorage.setItem('alias', value.value)
      }
      if (value.name === 'first_name') {
        obj.first_name = value.value;
        encryptStorage.setItem('client_first_name', value.value)
      }
      if (value.name === 'middle_initial') {
        obj.middle_initial = value.value;
        encryptStorage.setItem('client_middle_initial', value.value)
      }
      if (value.name === 'last_name') {
        obj.last_name = value.value;
        encryptStorage.setItem('client_last_name', value.value)
      }
      if (value.name === 'primary_email') {
        obj.primary_email = value.value;
        encryptStorage.setItem('email', value.value)
      }
      if (value.name === 'secondary_email') {
        obj.secondary_email = value.value;
      }
      if (value.name === 'primary_phone') {
        obj.primary_phone = value.value;
        if (value.value.length === 14) {
          context.setState({ disablePriMsg: false });
        }
        encryptStorage.setItem('phone', value.value)
      }
      if (value.name === 'leave_msg_pri') {
        obj.leave_msg_pri = value.value;
        sessionStorage.setItem('Leave_msg_pri', value.value);
      }
      if (value.name === 'secondary_phone') {
        obj.secondary_phone = value.value;
        if (value.value.length === 14) {
          context.setState({ disableSecMsg: false });
        }
        encryptStorage.setItem('Secondary_phone', value.value)
      }
      if (value.name === 'leave_msg_sec') {
        obj.leave_msg_sec = value.value;
        sessionStorage.setItem('Leave_msg_sec', value.value);
      }
      if (value.name === 'type_lns') {
        obj.type_lns = value.value;
        sessionStorage.setItem('Type_lns', value.value);
      }
      if (value.name === 'address_lns') {
        obj.address_lns = value.value;
      }
      if (value.name === 'city_lns') {

        obj.city_lns = value.value;
      }
      if (value.name === 'state_lns') {
        obj.state_lns = value.value;
      }
      if (value.name === 'county_lns') {
        obj.county_lns = value.value;
      }
      if (value.name === 'zipcode_lns') {
        obj.zipcode_lns = value.value;
      }
      if (value.name === 'is_mailing_same') {
        obj.is_mailing_same = value.value;
      }
      if (value.name === 'address_mailing') {
        obj.address_mailing = value.value;
      }
      if (value.name === 'city_mailing') {
        obj.city_mailing = value.value;
      }
      if (value.name === 'state_mailing') {
        obj.state_mailing = value.value;
      }
      if (value.name === 'gpra_no') {
        obj.gpra_no = value.value;
        encryptStorage.setItem('gpra_no', value.value);
      }
      if (value.name === 'county_mailing') {
        if (obj.is_mailing_same == true) {
          obj.county_mailing = obj.county_lns
        } else {
          obj.county_mailing = value.value;
        }
      }
      if (value.name === 'zipcode_mailing') {
        obj.zipcode_mailing = value.value;
      }
      if (value.name === 'gender') {
        obj.gender = value.value;
      }
      if (value.name === 'organization') {
        obj.organization = sessionStorage.getItem('selected_organization');
      }
      if (value.name === 'age') {
        obj.age = value.value;
      }
      if (value.name === 'ssn') {
        obj.ssn = value.value;
      }
      // Code Added Bby Ashraf 934
      if (value.name === 'visitType') {
        obj.visitType = value.value;
      }
      if (value.name == "founding_resource") {
        obj.founding_resource = value.value;
      }
      //
      if (value.name == "founding_resource_other") {
        obj.founding_resource_other = "test2";
        fundingSourceOther = value.value;
      }

      if (value.name === 'state_lns_served') {
        obj.state_lns_served = value.value;
      }
      if (value.name === 'county_lns_served') {
        obj.county_lns_served = value.value;
      }
      if (value.name === 'last_updated_by') {
        obj.last_updated_by = encryptStorage.getItem('last_updated_by');
      }

      if (value.name === 'client_races') {
        // client_races_fetch = value.value;
        // alert(JSON.stringify(value))
      }
      if (value.name === "client_import_status") {
        context.setState({ client_import_status: value.value });
        // obj.client_import_status = value.value;
      }

    });
    this.setState({
      intakeFormData: obj,
      state_saved: obj.state_lns !== '' ? false : false
    },
      async () => {
        let detaListBody = {
          apikey: sessionStorage.getItem('__TOKEN__'),
          state: this.state.intakeFormData.state_lns,
        }
        let detaListBody2 = {
          apikey: sessionStorage.getItem('__TOKEN__'),
          state: this.state.intakeFormData.state_lns_served,
        }
        let res_county_lns = await axios.post(global.restApiURL + "customRest/getCountyDropdwon", JSON.stringify(detaListBody))
        let res_county_lns_served = await axios.post(global.restApiURL + "customRest/getCountyDropdwon", JSON.stringify(detaListBody2))
        if (res_county_lns.data.success) {
          let tempOptions = res_county_lns.data.data?.length > 0 ? JSON.parse(res_county_lns.data.data) : [];
          this.setState({ countyOptions: tempOptions.results })
        }
        if (res_county_lns_served.data.success) {
          let tempOptions = res_county_lns_served.data.data?.length > 0 ? JSON.parse(res_county_lns_served.data.data).results.slice(0).sort(function (a, b) {
            var x = a.countyName.toLowerCase();
            var y = b.countyName.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          }) : [];
          this.setState({ countyServedOption: tempOptions });
        }
      });
    encryptStorage.setItem('Address_lns', obj.address_lns + " " + obj.city_lns + " " + obj.state_lns + " " + obj.county_lns + " " + obj.zipcode_lns);
    encryptStorage.setItem('address', obj.address_lns);
    encryptStorage.setItem('city_lns', obj.city_lns);
    encryptStorage.setItem('state_lns', obj.state_lns);
    encryptStorage.setItem('county_lns', obj.county_lns);
    encryptStorage.setItem('zipcode_lns', obj.zipcode_lns);
    this.createDropdownOptions();
  }

  handleId = (e) => {
    const { primary_phone, secondary_phone,ssn } = this.state.intakeFormData;
    let formattedPrimaryPhone = primary_phone;
    let formattedSecondaryPhone = secondary_phone;
    let formattedssn = ssn;
    // Remove all non-numeric characters from the phone number
    const numericPrimaryPhone = primary_phone.replace(/\D/g, '');
    const numericSecondaryPhone = secondary_phone.replace(/\D/g, '');
    const numericssn = ssn.replace(/\D/g, '');
    // Format the phone number as (999) 999-9999
    if (numericPrimaryPhone.length === 10) {
      formattedPrimaryPhone = `(${numericPrimaryPhone.substring(0, 3)}) ${numericPrimaryPhone.substring(3, 6)}-${numericPrimaryPhone.substring(6)}`;
    }
    if (numericSecondaryPhone.length === 10) {
      formattedSecondaryPhone = `(${numericSecondaryPhone.substring(0, 3)}) ${numericSecondaryPhone.substring(3, 6)}-${numericSecondaryPhone.substring(6)}`;
    }
    if (numericssn.length === 9) {
      formattedssn = `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(5)}`;
    }
    // Check if the formatted phone number is less than 14 characters (formatted length)
    if ((formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) || (formattedSecondaryPhone.length < 14 && formattedSecondaryPhone.length > 0) || ( formattedssn.length < 11 && formattedssn.length > 0)) {
      if (formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) {
        e.preventDefault();
        this.setState({
          errorMessage: "Please enter at least 10 digits."
        });
      }
      if (formattedSecondaryPhone.length < 14 && formattedSecondaryPhone.length > 0) {
        e.preventDefault();
        this.setState({
          errorMessageSecondary: "Please enter at least 10 digits."
        });
      }
      if (formattedssn.length < 11 && formattedssn.length > 0) {
        e.preventDefault();
        this.setState({
          ssnValidation:'Please enter ssn at least 9 digits.'
        });
      }
    } else {
      $('#clicked').val(e.target.value);
      $('#clicked').val(e.currentTarget.value);
    }
  };

  saveChanges() {
    //isko b dekhna hai
    const { primary_phone, secondary_phone,ssn } = this.state.intakeFormData;
    let formattedPrimaryPhone = primary_phone;
    let formattedSecondaryPhone = secondary_phone;
    let formattedssn = ssn;
    // Remove all non-numeric characters from the phone number
    const numericPrimaryPhone = primary_phone.replace(/\D/g, '');
    const numericSecondaryPhone = secondary_phone.replace(/\D/g, '');
    const numericssn = ssn.replace(/\D/g, '');

    // Format the phone number as (999) 999-9999
    if (numericPrimaryPhone.length === 10) {
      formattedPrimaryPhone = `(${numericPrimaryPhone.substring(0, 3)}) ${numericPrimaryPhone.substring(3, 6)}-${numericPrimaryPhone.substring(6)}`;
    }

    if (numericSecondaryPhone.length === 10) {
      formattedSecondaryPhone = `(${numericSecondaryPhone.substring(0, 3)}) ${numericSecondaryPhone.substring(3, 6)}-${numericSecondaryPhone.substring(6)}`;
    }
    if (numericssn.length === 9) {
      formattedssn = `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(5)}`;
    }
    // Check if the formatted phone number is less than 14 characters (formatted length)
    if ((formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) || (formattedSecondaryPhone.length < 14 && formattedSecondaryPhone.length > 0) || ( formattedssn.length < 11 && formattedssn.length > 0)) {
      if (formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) {
        this.setState({errorMessage : "Please enter at least 10 digits." });
      }
      if (formattedSecondaryPhone.length < 14 && formattedSecondaryPhone.length > 0) {
        this.setState({errorMessageSecondary : "Please enter at least 10 digits."});
      }
      if (formattedssn.length < 11 && formattedssn.length > 0) {
        this.setState({ssnValidation:'Please enter ssn at least 9 digits.'});
      }
    }else {
      $('#clicked').val('save');
      $('#intakebasicinfoform').submit();
    }
  }

  ssnChange = (event) => {
    var value = event.target.value;
    var newState = {
      mask: '9999-9999-9999-9999',
      value: value
    };
    if (/^3[47]/.test(value)) {
      newState.mask = '9999-999999-99999';
    }
    this.setState((prevState) =>
    ({
      ...prevState,
      intakeFormData: {
        ...prevState.intakeFormData,
        ssn: value
      }
    }));
  }

  handlePhoneChange = (event) => {
    if (event.target !== null) {
      let test = event.target.value;
      let disableSecMsg = true;
      let disablePriMsg = true;
      if (event.target.name === "secondary_phone") {
        if (this.state.intakeFormData.secondary_phone !== "") {
          this.setState({ errorMessageSecondary : ''});
        }
        if (test.length === 14) {
          disableSecMsg = false;
        }
        this.setState(prevState => ({
          ...prevState,
          unSaved: true,
          intakeFormData: {
            ...prevState.intakeFormData,
            secondary_phone: test
          },
          disableSecMsg
        }));
      }
      if (event.target.name === "primary_phone") {
        if (this.state.intakeFormData.primary_phone !== "") {
          this.setState({errorMessage : ''});
        }
        if (test.length === 14) {
          disablePriMsg = false;
        }
        this.setState(prevState => ({
          ...prevState,
          unSaved: true,
          intakeFormData: {
            ...prevState.intakeFormData,
            primary_phone: test
          },
          disablePriMsg
        }));
      }
      if (event.target.name === "ssn") {
        $("#saveButton").attr("disabled", false);
        $("#saveAndProceedButton").attr("disabled", false);
        $("#saveButton1").attr("disabled", false);
        $("#saveAndProceedButton1").attr("disabled", false);
        if (this.state.intakeFormData.ssn !== "") {
          this.setState({ ssnValidation: '' });
        }
        this.setState(prevState => ({
          ...prevState,
          unSaved: true,
          intakeFormData: {
            ...prevState.intakeFormData,
            ssn: test
          }
        }));
      }
    }
  }

  checkUnSavedChanges() {
    updatedFormValues = $('#intakebasicinfoform').serialize();
    if (initialFormValues !== updatedFormValues) {
      this.setState({ unSaved: true })
    }
  }

  beforeMaskedValueChange2 = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    if (value.endsWith('-') && userInput !== '-' && !this.state.intakeFormData.secondary_phone.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return {
      value,
      selection
    };
  }

  closeViewHistoryModal = (status) => {
    this.setState({ viewHistoryModal: status })
  }

  beforeMaskedValueChange1 = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    if (value.endsWith('-') && userInput !== '-' && !this.state.intakeFormData.primary_phone.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return {
      value,
      selection
    };
  }

  beforeMaskedValueChangeSSN = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    if (value.endsWith('-') && userInput !== '-' && !this.state.intakeFormData.ssn.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return {
      value,
      selection
    };
  }

  checkMailingAddress = (e) => {
    sessionStorage.setItem('is_mailing_same', e.target.checked);
    let intakeFormData = this.state['intakeFormData'];
    intakeFormData['is_mailing_same'] = e.target.checked;
    this.setState({ intakeFormData });
  }

  toggleSSN = () => {
    this.setState({ toggleSSN: !this.state.toggleSSN });
  }

  handleViewHistoryDetails = (event) => {
    this.setState({
      viewHistoryModal: true
    });
    if (sessionStorage.getItem('clientId') !== '') {
      axios.get(global.restApiURL + 'webservice/rest/object-list?apikey=' + sessionStorage.getItem('__TOKEN__') + '&limit=1&objectClass=ClientInformation&q={"client_id":"' + sessionStorage.getItem('clientId') + '"}')
        .then(res => {
          const responce = res.data.data; // get the data array instead of object
          if (responce[0] !== undefined || responce[0].id !== '') {
            axios.get(global.restApiURL + 'webservice/rest/object/id/' + responce[0].id + '?apikey=' + sessionStorage.getItem('__TOKEN__'))
              .then(res => {
                sessionStorage.setItem('responceId', res.data.data.id);
                this.setState({ object_id: res.data.data.id });
                const responce = res.data.data.elements;
                this.datamapper(responce);
                let parsedClientsInfo = formObjDataMapper2(responce, this.getModal());
                this.setState({ initialClientsInfo: parsedClientsInfo });
                var rules = [];
                var regex_var = {};
                var realArray = this.state.regex;
                Object.entries(realArray).map(function ([field, reg_ex], i) {
                  if (reg_ex !== '' && reg_ex !== undefined) {
                    regex_var[field] = '/' + reg_ex + '/';
                    $.validator.addMethod(regex_var[field], function (value, element, parameter) {
                      return value.match(regex_var[field]);
                    }, '');
                    rules[field] = { [regex_var[field]]: true };
                  }
                });
                initialFormValues = $('#intakebasicinfoform').serialize();
              })
              .catch(error => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });

                if (error.response) {
                  // Request made and server responded
                  alert(error.response.data.msg);
                  if (error.response.status === 401 || error.response.status === 403) {
                    window.location.href = global.domain + global.traversArray['out'];
                  }
                } else if (error.request) {
                  // The request was made but no response was received
                  alert(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  alert(error.message);
                }

              })
          }
        })
        .catch(error => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          if (error.response) {
            // Request made and server responded
            alert(error.response.data.msg);
            if (error.response.status === 401 || error.response.status === 403) {
              window.location.href = global.domain + global.traversArray['out'];
            }
          } else if (error.request) {
            // The request was made but no response was received
            alert(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            alert(error.message);
          }
        })
    }
  }

  handleTimeronBlur(date, field) {
    $('#dob').valid(); 
    date = context.state.intakeFormData[field];
    let intakeFormData_temp = this.state.intakeFormData;
    if(typeof date === 'string' && date !== ""){
      intakeFormData_temp[field] =  date.length == 10 ? moment.utc(date).format('MM/DD/YYYY') : date;
    }else if(typeof date === 'object' && date !== ""){
      intakeFormData_temp[field] = moment.utc(date).format('MM/DD/YYYY');
    }else{
      intakeFormData_temp[field] = "";
    }
    context.setState({ intakeFormData: intakeFormData_temp, unSaved: true });
  };

  handleTimer(date, field) {
    let intakeData_temp = this.state.intakeFormData;
    if (field === 'dob') {
      let age = this.getAge(moment.utc(date).toDate());
      intakeData_temp['age'] = isNaN(age) ? "" : age;
    }
    //let x=moment().format('MM/DD/YYYY')
    intakeData_temp[field] = date._id !== undefined ? moment.utc(date._d).format('MM/DD/YYYY') : date;
    context.setState({ intakeFormData: intakeData_temp, unSaved: true });
  };

  validDobDate(current, selectedDate) {
    return selectedDate?.isBefore(moment().subtract(context?.state?.min_dob, 'years')) && selectedDate?.isAfter(moment().subtract(120, 'years'));
  };

  handleOtherInputChange = event => {
    // debugger
    var stateCopy = Object.assign({}, this.state);
    if (event.target.name === 'hisp_lat_heritage') {
      // stateCopy.intakeFormData[event.target.name] = event.target.value;
      stateCopy.intakeFormData.hispLatHeritage = event.target.value;
      this.setState(stateCopy);
    } else {
      fundingSourceOther = event.target.value;
      this.setState({ founding_resource_other: event.target.value });
    }
  }

  closePromptBox = () => {
    // let dataList = {
    //   "dataListBody": {
    //     apikey: sessionStorage.getItem('__TOKEN__'),
    //     client_id: sessionStorage.getItem('clientId'),
    //     client_import_status: false
    //   }
    // }
    // axios.post(global.restApiURL + "customRest/clientUpdateImportStatus", JSON.stringify(dataList))
    //   .then(function (res) {
    //     console.log(res);
    //   })
    //   .catch(function (err) {
    //     console.log("error", err.message);
    //   }
    //   );
    this.setState({ client_import_status: false });
  }

  render() {
    if (encryptStorage.getItem('last_updated_by') === '' || encryptStorage.getItem('last_updated_by') === null) {
      encryptStorage.setItem('last_updated_by', sessionStorage.getItem('loggedinusername'));
    }
    sessionStorage.setItem('formname', 'Client Information')
    var disabled = "disabled";
    $(document).ready(function () {
      var NumRegex = /^[0-9]+$|^$|^\s$/;
      var EmailRegex = /^$|^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
      var cityRegEx = /^[a-zA-Z ]*$/;

      $.validator.addMethod('NumRegex', function (value, element, parameter) {
        return value.match(NumRegex);
      }, 'Please enter a valid zipcode.');

      $.validator.addMethod('EmailRegex', function (value, element, parameter) {
        return value.match(EmailRegex);
      }, 'Please enter a valid email address.');

      $.validator.addMethod('cityRegEx', function (value, element, parameter) {
        return value.match(cityRegEx);
      }, 'Please enter a valid city name');

      $.validator.addMethod('DobRegex', function (value, element, parameter) {
        return context.validDobDate(moment(value), moment(value));
      }, 'Age should be >= 14 and <= 120  .');

      $('#intakebasicinfoform').validate({
        rules: {
          interviewer: {
            required: true,
          },
          visitType: {
            required: true,
          },
          founding_resource_other: {
            required: true,
          },
          primary_phone: {
            required: false,
          },
          zipcode_lns: {
            NumRegex: true
          },
          zipcode_mailing: {
            NumRegex: true
          },
          primary_email: {
            EmailRegex: true
          },
          secondary_email: {
            EmailRegex: true
          },
          dob: {
            DobRegex: true
          },
          city_lns: {
            cityRegEx: true
          },
          city_mailing: {
            cityRegEx: true
          }
        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
          error.addClass('invalid-feedback');
          element.closest('.form-group').append(error);
        },
        highlight: function (element, errorClass, validClass) {
          $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).removeClass('is-invalid');
        },
        onkeyup: function (element) {
          $('#zipcode_lns').valid();
          $('#primary_email').valid();
          $('#secondary_email').valid();
          $('#zipcode_mailing').valid();
          // $('#dob').valid();
        },
        onchange: function (element) {
          $('#dob').valid();
        }
      });
    });

    const years = moment().subtract(200, 'year');
    const disablePastDt = current => {
      return current.isAfter(years);
    };

    let phone = encryptStorage.getItem('phone')
    let mandatory_peer = this.state.required.peer_support_specialist ? this.state.required.peer_support_specialist : false;
    let maxLength_peer = this.state.maxLength.peer_support_specialist ? this.state.maxLength.peer_support_specialist : '';
    const inputProps = { name: 'peer_support_specialist', id: 'peer_support_specialist', required: maxLength_peer, maxLength: maxLength_peer, class: "form-group" };
    // limit date of birth minimum 14 years
    let min_dob = new Date();
    min_dob.setFullYear(min_dob.getFullYear() - this.state.min_dob);
    return (

      <div className="hold-transition sidebar-mini text-sm">
        <ConfirmBeforeLeave onRef={ref => (this.childForm = ref)} unSaved={context.state.unSaved} onSaveRequest={() => context.saveChanges()} />
        <ReactTooltip place="right" type="dark" effect="solid" />
        <div className="wrapper">
          <Topnav />
          <div className="content-wrapper">
            <Patientinfoheader clientId={sessionStorage.getItem('clientId')} dob={encryptStorage.getItem('dob')} email={encryptStorage.getItem('email')} mask="(999) 999-9999" phone={encryptStorage.getItem('phone')} peer_support_specialist={encryptStorage.getItem('peer_support_specialist')} next_followup_date={encryptStorage.getItem('month_1_followup_date')} intake_date={encryptStorage.getItem('intake_date')} initiated_by={encryptStorage.getItem('last_updated_by')} formname={sessionStorage.getItem('formname')} client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')} />
            <section className="content">
              <div className="row">
                <Sidenav />
                <div className="container-fluid col-md-9 col-lg-10">
                  <form method="post" id="intakebasicinfoform">
                    <fieldset disabled={!can_edit('ClientInformation')}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card card-default">
                            <div className="card-header">
                              <p className="lead float-left" ><b className="text-green">Client Information</b> <small> Capture basic client information</small></p>
                              {can_view_version('ClientInformation') ? (<a data-toggle="modal" data-target="#modal-history" className="btn btn-default float-right" onClick={() => this.handleViewHistoryDetails()}> View History</a>) : ''}&nbsp;&nbsp;
                              <button type="submit" className="btn btn-primary float-right btnmargin saveNproceed" id='saveAndProceedButton1' value="proceed" onClick={this.handleId}>
                                <img src="/dist/img/save-proceed_icon.png" alt="" /> Save &amp; Proceed
                              </button>&nbsp;&nbsp;
                              <button type="submit" data-save="save" value='save' id="saveButton1" className="btn btn-success float-right btnmargin" onClick={this.handleId}> <i className="fas fa-save"></i> Save</button>
                              <input type="hidden" id="clicked" value="" readOnly />
                              <input type="hidden" id="client_id" name="client_id" defaultValue={sessionStorage.getItem('clientId')} />
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 col-md-4">
                                  <div className="form-group">
                                    <label>{this.state.labels.peer_support_specialist}</label> {this.state.required.peer_support_specialist === true ? (<span className="required">*</span>) : ''} <Tooltip text={this.state.tooltip.peer_support_specialist} visibiity={this.state.tooltipVisibility.peer_support_specialist} />
                                    <Typeahead
                                      inputProps={inputProps}
                                      labelKey="full_name"
                                      options={this.state.peerSupportOptions}
                                      placeholder="Assigned Specialist"
                                      id="peer_support_specialist"
                                      selected={this.state.peerSupportOptions.filter((user) => ((this.state.intakeFormData.peer_support_specialist != '' ? this.state.intakeFormData.peer_support_specialist : encryptStorage.getItem('peer_support_specialist')) === user.full_name))}
                                      onChange={this.handleDataChange1}
                                      onInputChange={(s) => this.handleDataChange1}
                                    />
                                  </div>
                                </div>
                                <div className=" col-lg-3 col-md-4">
                                  <div className="form-group">
                                    <label>{this.state.labels.interviewer}</label> {this.state.required.interviewer === true ? (<span className="required">*</span>) : ''} <Tooltip text={this.state.tooltip.interviewer} visibiity={this.state.tooltipVisibility.interviewer} />
                                    <input type="text" maxLength={this.state.maxLength.interviewer} className="form-control" id="interviewer" name="interviewer" placeholder="interviewer"
                                      defaultValue={encryptStorage.getItem('last_updated_by')} required={this.state.required.interviewer} readOnly />
                                  </div>
                                </div>
                                <div className=" col-lg-2 col-md-4">
                                  <label>{this.state.labels.intake_date} (MM/DD/YYYY)</label> {this.state.required.intake_date === true ? (<span className="required">*</span>) : ''} <Tooltip text={this.state.tooltip.intake_date} visibiity={this.state.tooltipVisibility.intake_date} />
                                  <div className="form-group">
                                    <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                      <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                    </div>
                                    <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>
                                      <span>
                                        <DateTime
                                          onBlur={(date) => this.handleTimeronBlur(date, 'intake_date')}
                                          onChange={(date) => this.handleTimer(date, 'intake_date')}
                                          inputProps={{ value: typeof context.state.intakeFormData.intake_date === 'string' ? context.state.intakeFormData.intake_date : moment.utc(context.state.intakeFormData.intake_date).format('MM/DD/YYYY'), required: this.state.required.intake_date, name: 'intake_date' }}
                                          defaultValue={moment.utc().format('MM/DD/YYYY')}
                                          dateFormat="MM/DD/YYYY" timeFormat={false}
                                          input={true}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2" style={{ display: "none" }}>
                                  <div className="form-group">
                                    <label>{this.state.labels.month_1_followup_date}</label> {this.state.required.month_1_followup_date === true ? (<span className="required">*</span>) : ''} <Tooltip text={this.state.tooltip.month_1_followup_date} visibiity={this.state.tooltipVisibility.month_1_followup_date} />
                                    <div className="input-group date">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="far fa-calendar-alt"></i>
                                        </span>
                                      </div>
                                      <DatePicker dateFormat="MM/dd/yyyy" className="form-control pull-right" selected={this.state.intakeFormData.month_1_followup_date} onChange={this.handlemonth1followupdate} id="month_1_followup_date" name="month_1_followup_date" showYearDropdown />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2" style={{ display: "none" }} >
                                  <div className="form-group">
                                    <label>{this.state.labels.month_6_followup_date}</label> {this.state.required.month_6_followup_date === true ? (<span className="required">*</span>) : ''} <Tooltip text={this.state.tooltip.month_6_followup_date} visibiity={this.state.tooltipVisibility.month_6_followup_date} />
                                    <div className="input-group date">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="far fa-calendar-alt"></i>
                                        </span>
                                      </div>
                                      <DatePicker dateFormat="MM/dd/yyyy" className="form-control pull-right" selected={this.state.intakeFormData.month_6_followup_date} onChange={this.handleChangesixmonthfollow} id="month_6_followup_date" name="month_6_followup_date" showYearDropdown />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4"  >
                                  <div className="form-group">
                                    <InputTextField labelText={this.state.labels.gpra_no} type="text" maxLength={this.state.maxLength.gpra_no} className="form-control" placeholder={this.state.labels.gpra_no} id="gpra_no" name="gpra_no" defaultValue={this.state.intakeFormData.gpra_no} required={this.state.required.gpra_no} tooltipText={this.state.tooltip.gpra_no} tooltipVisibility={this.state.tooltipVisibility.gpra_no} onChange={this.handleDataChange} />
                                  </div>
                                </div>

                              </div>
                              <div className='row'>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Visit Type</label><span className="required"> *</span>
                                    <select className="custom-select float-right" id="visitType" name="visitType" required={true} defaultValue={this.state.intakeFormData.visitType} onChange={this.handleDataChange}>
                                      {this.state.visitTypeOptions}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label htmlFor="fundingResources">Funding Source</label><span style={{ color: "red" }}> *</span>
                                    <select className="custom-select float-right" id="founding_resource" name="founding_resource" required={true} defaultValue={this.state.intakeFormData.founding_resource} onChange={this.handleDataChange}>
                                      {this.state.foundingResourceOptions}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  {this.state.intakeFormData.founding_resource == 'Other' && (
                                    <div className="form-group">
                                      <label htmlFor="otherValue">Please Specify</label><span style={{ color: "red" }}> *</span>
                                      <input
                                        type="text"
                                        value={this.state.founding_resource_other == "" ? fundingSourceOther : this.state.founding_resource_other}
                                        onChange={this.handleOtherInputChange}
                                        className="form-control"
                                        id="founding_resource_other"
                                        name="founding_resource_other"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>

                              <hr />
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <InputTextField labelText={this.state.labels.first_name} placeholder={this.state.labels.first_name} type="text" maxLength={this.state.maxLength.first_name} className="form-control" id="first_name" name="first_name" defaultValue={this.state.intakeFormData.first_name} required={true} tooltipText={this.state.tooltip.first_name} tooltipVisibility={this.state.tooltipVisibility.first_name} onChange={this.handleDataChange} />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <InputTextField labelText={this.state.labels.middle_initial} placeholder={this.state.labels.middle_initial} type="text" maxLength={this.state.maxLength.middle_initial} className="form-control" id="middle_initial" name="middle_initial" defaultValue={this.state.intakeFormData.middle_initial} required={this.state.required.middle_initial} tooltipText={this.state.tooltip.middle_initial} tooltipVisibility={this.state.tooltipVisibility.middle_initial} onChange={this.handleDataChange} />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <InputTextField labelText={this.state.labels.last_name} placeholder={this.state.labels.last_name} type="text" maxLength={this.state.maxLength.last_name} className="form-control" id="last_name" name="last_name" defaultValue={this.state.intakeFormData.last_name} required={true} tooltipText={this.state.tooltip.last_name} tooltipVisibility={this.state.tooltipVisibility.last_name} onChange={this.handleDataChange} />
                                  </div>
                                </div>
                                <div className="col-md-1">
                                  <div className="form-group">
                                    <label>Suffix</label>  <Tooltip text={this.state.tooltip.suffix} visibiity={this.state.tooltipVisibility.suffix} />
                                    <select className="custom-select float-right" id="suffix" name="suffix" required={this.state.required.suffix} onChange={this.handleDataChange}>
                                      {this.state.suffixOption}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-md-2">
                                  <div className="form-group">
                                    <InputTextField labelText={this.state.labels.alias} placeholder={this.state.labels.alias} type="text" className="form-control" id="alias" name="alias" defaultValue={this.state.intakeFormData.alias} required={this.state.required.alias} tooltipText={this.state.tooltip.alias} tooltipVisibility={this.state.tooltipVisibility.alias} onChange={this.handleDataChange} />
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>{this.state.labels.dob} (MM/DD/YYYY)</label><span className="required">*</span><Tooltip text={this.state.tooltip.dob} visibiity={this.state.tooltipVisibility.dob} />
                                    <div>
                                      <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                        <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                      </div>
                                      <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>
                                        <span>
                                          <DateTime
                                            onBlur={(date) => this.handleTimeronBlur(date, 'dob')}
                                            onChange={(date) => this.handleTimer(date, 'dob')}
                                            inputProps={{ id: 'dob', value: typeof context.state.intakeFormData.dob === 'string' ? context.state.intakeFormData.dob : moment.utc(context.state.intakeFormData.dob).format('MM/DD/YYYY'), required: this.state.required.dob, name: 'dob', placeholder: 'Date of birth' }}
                                            defaultValue={moment.utc().subtract(this.state.min_dob, 'years').format('MM/DD/YYYY')}
                                            dateFormat="MM/DD/YYYY" timeFormat={false}
                                            placeholderText='Date of Birth'
                                            input={true}
                                            required={true}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>Age</label> {this.state.required.age === true ? (<span className="required">*</span>) : ''} <Tooltip text={this.state.tooltip.dob} visibiity={this.state.tooltipVisibility.dob} />
                                    <input type="text" maxLength={this.state.maxLength.age} className="form-control" id="age" name="age" placeholder="Age" defaultValue={this.state.intakeFormData.age} required={this.state.required.age} readOnly onChange={this.handleDataChange} />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>Gender</label> {this.state.required.gender === true ? (<span className="required">*</span>) : ''} <Tooltip text={this.state.tooltip.gender} visibiity={this.state.tooltipVisibility.gender} />
                                    <select className="custom-select float-right" id="gender" name="gender" required={this.state.required.gender} onChange={this.handleDataChange}>
                                      {this.state.genderOptions}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>SSN</label> {this.state.required.ssn === true ? (<span className="required">*</span>) : ''} <Tooltip text={this.state.tooltip.ssn} visibiity={this.state.tooltipVisibility.ssn} />
                                    <div className="input-with-icon">
                                      {!can_edit('ClientInformation') || (this.state.toggleSSN && this.state.intakeFormData.ssn !== null && this.state.intakeFormData.ssn !== undefined && this.state.intakeFormData.ssn !== '' && this.state.intakeFormData.ssn.replace(/-/g, "").length >= 9) ? (
                                        <div>
                                          <input type="text" id="ssn_view" placeholder="SSN" name="ssn_text" className="form-control" value={`***-**-` + this.state.intakeFormData.ssn.substr(this.state.intakeFormData.ssn.length - 4)} onFocus={this.toggleSSN} />
                                          <input type="hidden" id="ssn" name="ssn" className="form-control" value={this.state.intakeFormData.ssn} />
                                        </div>) : (
                                        <InputMask type='text' placeholder="SSN" id="ssn" name="ssn" className="form-control" mask="999-99-9999" maskChar={null} value={this.state.intakeFormData.ssn} beforeMaskedValueChange={this.beforeMaskedValueChangeSSN} onChange={this.handlePhoneChange} required={this.state.required.ssn}  onBlur={this.toggleSSN} onMouseLeave={this.toggleSSN} />)}
                                        <span style={{ color: 'red', fontSize: "75%" }}>{this.state.intakeFormData.ssn === "" ? "" : this.state.ssnValidation}</span>
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="row">
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <label>{this.state.labels.primary_email}</label> {this.state.required.primary_email === true ? (<span className="required">*</span>) : ''} <Tooltip text={this.state.tooltip.primary_email} visibiity={this.state.tooltipVisibility.primary_email} />
                                    <input type="email" placeholder={this.state.labels.primary_email} pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$" className="form-control" id="primary_email" name="primary_email" defaultValue={this.state.intakeFormData.primary_email} required={this.state.required.primary_email} onChange={this.handleDataChange} />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <label>{this.state.labels.secondary_email}</label> {this.state.required.secondary_email === true ? (<span className="required">*</span>) : ''} <Tooltip text={this.state.tooltip.secondary_email} visibiity={this.state.tooltipVisibility.secondary_email} />
                                    <input type="email" placeholder={this.state.labels.secondary_email} pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$" className="form-control" id="secondary_email" name="secondary_email" defaultValue={this.state.intakeFormData.secondary_email} required={this.state.required.secondary_email} onChange={this.handleDataChange} />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <label>{this.state.labels.primary_phone}</label> {this.state.required.primary_phone === true ? (<span className="required">*</span>) : ''} <Tooltip text={this.state.tooltip.primary_phone} visibiity={this.state.tooltipVisibility.primary_phone} />
                                    <InputMask id="primary_phone" placeholder={this.state.labels.primary_phone} name="primary_phone" className="form-control" mask="(999) 999-9999" maskChar={null} value={this.state.intakeFormData.primary_phone} beforeMaskedValueChange={this.beforeMaskedValueChange1} onChange={this.handlePhoneChange} required={this.state.required.primary_phone} maxLength={this.state.maxLength.primary_phone} />
                                    <span style={{ color: 'red', fontSize: "75%" }}>{this.state.intakeFormData.primary_phone === "" ? "" : this.state.errorMessage}</span>
                                  </div>
                                </div>
                                <div className="col-lg-2 col-md-3">
                                  <div className="form-group">
                                    <label>{this.state.labels.leave_msg_pri}</label> {this.state.required.leave_msg_pri === true ? (<span className="required">*</span>) : ''} <Tooltip text={this.state.tooltip.leave_msg_pri} visibiity={this.state.tooltipVisibility.leave_msg_pri} />
                                    <select className="custom-select" id="leave_msg_pri" name="leave_msg_pri" disabled={this.state.disablePriMsg} required={this.state.required.leave_msg_pri} onChange={this.handleDataChange}>
                                      {this.state.leaveMessagePrimaryOptions}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-2 ">
                                  <div className="form-group">
                                    <label>{this.state.labels.secondary_phone}</label> {this.state.required.secondary_phone === true ? (<span className="required">*</span>) : ''} <Tooltip text={this.state.tooltip.secondary_phone} visibiity={this.state.tooltipVisibility.secondary_phone} />
                                    <InputMask placeholder={this.state.labels.secondary_phone} className="form-control" mask="(999) 999-9999" maskChar={null} id="secondary_phone" name="secondary_phone" value={this.state.intakeFormData.secondary_phone} beforeMaskedValueChange={this.beforeMaskedValueChange2} onChange={this.handlePhoneChange} required={this.state.required.secondary_phone} maxLength={this.state.maxLength.secondary_phone} />
                                    <span style={{ color: 'red', fontSize: "75%" }}>{this.state.intakeFormData.secondary_phone === "" ? "" : this.state.errorMessageSecondary}</span>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <label>{this.state.labels.leave_msg_sec}</label> {this.state.required.leave_msg_sec === true ? (<span className="required">*</span>) : ''} <Tooltip text={this.state.tooltip.leave_msg_sec} visibiity={this.state.tooltipVisibility.leave_msg_sec} />
                                    <select className="custom-select" id="leave_msg_sec" name="leave_msg_sec" disabled={this.state.disableSecMsg} required={this.state.required.leave_msg_sec} onChange={this.handleDataChange}>
                                      {this.state.leaveMessageSecondaryOptions}
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className='row mb-2'>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>{this.state.client_races_label}</label>
                                    <Select
                                      name={this.state.client_races_name}
                                      isMulti
                                      closeMenuOnSelect={false}
                                      value={this.state.client_races_selected_op}
                                      onChange={this.handle_client_races}
                                      options={this.state.clientRacesMultiOptions}
                                      // styles={colourStyles}
                                      placeholder="Select"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    {/* <LabelField title={this.state.hispLatHeritageLabel} name="hisp_lat_heritage" tooltip={this.state.hispLatHeritageLabel} id="hisp_lat_heritage" /> */}
                                    <label>{this.state.hispLatHeritageLabel}</label>
                                    <select className="custom-select" id="hisp_lat_heritage" value={this.state.intakeFormData.hispLatHeritage} name="hisp_lat_heritage" onChange={this.handleOtherInputChange}>
                                      {this.state.hispLatHeritageOption}
                                    </select>
                                  </div>
                                </div>
                              </div>


                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="card">
                                    <div className="card-header">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <h3 className="card-title">{this.state.labels.type_lns}</h3><Tooltip text={this.state.tooltip.type_lns} visibiity={this.state.tooltipVisibility.type_lns} />
                                        </div>
                                        <div className="col-md-6">
                                          <select className="custom-select float-right" id="type_lns" name="type_lns" required={this.state.required.type_lns} onChange={this.handleDataChange}>
                                            {this.state.lastNightStayOptions}
                                          </select>
                                          {this.state.required.type_lns === true ? (<span className="required">*</span>) : ''} <Tooltip text={this.state.tooltip.type_lns} visibiity={this.state.tooltipVisibility.type_lns} />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      <div className="row">
                                        <LocationSearchInput
                                          value={this.state.intakeFormData.address_lns}
                                          onChange={this.handleChange_gpi}
                                          onSelect={this.handleSelect}
                                          placeholder="Address"
                                          id="address_lns"
                                          name="address_lns"
                                          defaultValue={this.state.intakeFormData.address_lns}
                                          required={this.state.required.address_lns}
                                        />
                                        <div className="col-md-8 form-group">
                                          <input type="text" maxLength={this.state.maxLength.city_lns} className="form-control" id="city_lns" name="city_lns" onChange={this.handleUpdateState} placeholder="City" defaultValue={this.state.intakeFormData.city_lns} required={this.state.required.city_lns} />
                                        </div>
                                        <div className="col-md-4 form-group">
                                          {this.state.intakeFormData.type_lns == "Streets or Outdoors" ? <>
                                            <select className="custom-select" disabled={false} id="state_lns" name="state_lns" onChange={this.handleUpdateState} required={this.state.required.state_lns} value={this.state.intakeFormData.state_lns}>
                                              {this.state.stateLNSOptions}
                                            </select>
                                          </> : <>
                                            <select className="custom-select" disabled={true} id="state_lns" name="state_lns" onChange={this.handleUpdateState} required={this.state.required.state_lns} value={this.state.intakeFormData.state_lns}>
                                              {this.state.stateLNSOptions}
                                            </select>
                                            <input type="hidden" name="state_lns" id="state_lns" value={this.state.intakeFormData.state_lns} />
                                          </>}
                                        </div>
                                        <div className="col-md-8 form-group">
                                          {this.state.intakeFormData.type_lns == "Streets or Outdoors" ? <>
                                            <select className="custom-select" disabled={false} id="county_lns" name="county_lns" onChange={this.handleUpdateState} required={this.state.required.county_lns} value={this.state.intakeFormData.county_lns}>
                                              <option>Select</option>
                                              {this.state.countyOptions?.map((val, key) => {
                                                return (<option value={val.countyName}>{val.countyName}</option>)
                                              })}
                                              {/* countyOptions */}
                                            </select>
                                          </> : <>
                                            <input type="text" disabled={true} maxLength={this.state.maxLength.county_lns} className="form-control" id="county_lns" name="county_lns_old" placeholder="County" value={this.state.intakeFormData.county_lns} onChange={this.handleUpdateState} required={this.state.required.county_lns} />
                                            <input type="hidden" maxLength={this.state.maxLength.county_lns} className="form-control" id="county_lns_get" name="county_lns" placeholder="County" value={this.state.intakeFormData.county_lns} onChange={this.handleUpdateState} required={this.state.required.county_lns} />
                                          </>}
                                        </div>
                                        <div className="col-md-4 form-group">
                                          <input type="text" maxLength={this.state.maxLength.zipcode_lns} className="form-control" id="zipcode_lns" name="zipcode_lns" placeholder="Zip Code" defaultValue={this.state.intakeFormData.zipcode_lns} onChange={this.handleUpdateState} required={this.state.required.zipcode_lns} />
                                        </div>
                                        {/*============= Fields for Peer Supports specialist Start ================*/}
                                        <div className="col-md-12 form-group">
                                          <span>Coverage Area for {sessionStorage.getItem('selected_organization')} </span>
                                        </div>
                                        <div className="col-md-4 form-group">
                                          <select className="custom-select" disabled={false} id="state_lns_served" name="state_lns_served" onChange={this.handleUpdateState} required={this.state.required.state_lns_served} value={this.state.intakeFormData.state_lns_served}>
                                            {this.state.stateLNSOptions}
                                          </select>
                                        </div>
                                        <div className="col-md-8 form-group">
                                          <select className="custom-select" disabled={false} id="county_lns_served" name="county_lns_served" onChange={this.handleUpdateState} required={this.state.required.county_lns_served} value={this.state.intakeFormData.county_lns_served}>
                                            <option value=''>Select</option>
                                            {this.state.countyServedOption?.map((val, key) => {
                                              return (<option value={val.countyName}>{val.countyName}</option>)
                                            })}
                                          </select>
                                        </div>
                                        {/*============= Fields for Peer Supports specialist End ================*/}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="card">
                                    <div className="card-header">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <span className="card-title">Mailing Address  <input className="ml-2 mr-1" type="checkbox" checked={this.state.intakeFormData.is_mailing_same} name="is_mailing_same" onClick={this.handleUpdateState} /><small>(check if same as last night's address)</small></span>
                                          {this.state.required.is_mailing_same === true ? (<span className="required">*</span>) : ''} <Tooltip text={this.state.tooltip.is_mailing_same} visibiity={this.state.tooltipVisibility.is_mailing_same} />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      <div className="row">
                                        <LocationSearchInput
                                          value={this.state.intakeFormData.address_mailing}
                                          onChange={this.handleChange_gpi_mailing_address}
                                          onSelect={this.handleSelect_mailing_address}
                                          placeholder="Address"
                                          id="address_mailing"
                                          name="address_mailing"
                                          defaultValue={this.state.intakeFormData.address_mailing}
                                          required={this.state.required.address_mailing}
                                          readOnly={this.state.intakeFormData.is_mailing_same === true}
                                        />
                                        <div className="col-md-8 form-group">
                                          <input type="text" maxLength={this.state.maxLength.city_mailing} className="form-control" id="city_mailing" name="city_mailing" placeholder="City" defaultValue={this.state.intakeFormData.city_mailing} required={this.state.required.city_mailing} value={this.state.intakeFormData.city_mailing} readOnly={this.state.intakeFormData.is_mailing_same === true} onChange={this.handleMailingBoxUpdate} />
                                        </div>
                                        <div className="col-md-4 form-group">
                                          <select className="custom-select" id="state_mailing" name="state_mailing" required={this.state.required.state_mailing} value={this.state.intakeFormData.state_mailing} disabled={this.state.intakeFormData.is_mailing_same === true} onChange={this.handleUpdateState} >
                                            {this.state.stateMailingAddressOptions}
                                          </select>
                                          {this.state.intakeFormData.is_mailing_same === true ? (<input type="hidden" name="state_mailing" value={this.state.intakeFormData.state_lns} />) : ''}
                                        </div>
                                        <div className="col-md-8 form-group">

                                          {this.state.intakeFormData.type_lns == "Streets or Outdoors" ? <>
                                            <select className="custom-select" id="county_mailing" name="county_mailing" onChange={this.handleMailingBoxUpdate} required={this.state.required.county_mailing} value={this.state.intakeFormData.county_mailing} disabled={this.state.intakeFormData.is_mailing_same === true} >
                                              <option>Select</option>
                                              {this.state.countyOptions?.map((val, key) => {
                                                return (<option value={val.countyName}>{val.countyName}</option>)
                                              })}
                                              {/* countyOptions */}
                                            </select>
                                          </> : <>
                                            <input type="text" maxLength={this.state.maxLength.county_mailing} className="form-control" id="county_mailing" name="county_mailing" onChange={this.handleMailingBoxUpdate} placeholder="County" defaultValue={this.state.intakeFormData.county_mailing} required={this.state.required.county_mailing} value={this.state.intakeFormData.county_mailing} readOnly={this.state.intakeFormData.is_mailing_same === true} />
                                          </>}
                                        </div>
                                        <div className="col-md-4 form-group">
                                          <input type="text" maxLength={this.state.maxLength.zipcode_mailing} className="form-control" id="zipcode_mailing" name="zipcode_mailing" onChange={this.handleMailingBoxUpdate} placeholder="Zip Code" defaultValue={this.state.intakeFormData.zipcode_mailing} value={this.state.intakeFormData.zipcode_mailing} readOnly={this.state.intakeFormData.is_mailing_same === true} required={this.state.required.zipcode_mailing} />
                                        </div>
                                        <div>
                                          <input type="hidden" name="client_import_status" value={context.state.client_import_status} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>


                            {this.state.client_import_status ? <>
                              <Modal showCloseButton={false} className="modal-lg" showOverlay={true}>
                                <Modal.Header>
                                  <Modal.Title>
                                    <h4 className="modal-title float-left text-green">For your Information</h4>
                                    <button className='btn btn-primary float-right' onClick={this.closePromptBox}>X</button>
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <p>Due to the data not having been previously captured in the ROSS system, some required data in CHIPSS may be missing for this client and the date of birth field will need to be updated.</p>
                                </Modal.Body>
                                <Modal.Footer>
                                  {/* <button className='btn btn-primary btn-lg' onClick={this.closePromptBox}>OK</button> */}
                                </Modal.Footer>
                              </Modal>
                            </>
                              : ""
                            }




                            <div className="card-footer">
                              {can_view_version('ClientInformation') ? (<a data-toggle="modal" data-target="#modal-history" className="btn btn-default float-right " style={{ marginLeft: '5px' }} onClick={() => this.handleViewHistoryDetails()}>View History</a>) : ""}
                              <button type="submit" className="btn btn-primary float-right saveNproceed" value="proceed" id='saveAndProceedButton' name='saveAndProceedButton' onClick={this.handleId}>
                                <img src="/dist/img/save-proceed_icon.png" alt="" /> Save &amp; Proceed
                              </button>
                              <button type="submit" id="saveButton" name='saveButton' className="btn btn-success float-right btnmargin" value="save" onClick={this.handleId}> <i className="fas fa-save"></i> Save</button>
                            </div>
                            <input type="hidden" name="last_updated_by" id="last_updated_by" value={sessionStorage.getItem('loggedinusername')} />
                            <input type="hidden" name="form_name" id="form_name" value='basicinfo' />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </section>
          </div>
          {this.state.viewHistoryModal && this.state.peerSupportOptions && <ViewHistoryModal viewHistoryModal={this.state.viewHistoryModal} class_name={'ClientInformation'} onRef={ref => (this.childForm = ref)} class_definition={this.state.full_labels} onViewHistoryModalClose={this.closeViewHistoryModal} obj_id={this.state.object_id} users_list={this.state.peerSupportOptions} />}
          <Footer />
        </div>
      </div>
    );
  }
}
export default Intakebasicinfo
