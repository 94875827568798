import React from "react";
import styled from "styled-components";

const MainContainer = styled.section`
  width: 24%;
  height: 168px;
  margin-top: 34px;
  margin-right: 32px;
  background: ${(props) => props.bgColor && props.bgColor};
  border-radius: 16px;
  padding: 10px 0;

  @media (max-width: 1360px) {
    width: 23%;

    margin-right: 20px;
  }
  @media (max-width: 1312px) {
    width: 25%;
  }
  @media (max-width: 1106px) {
    margin-right: 20px;
  }
  @media (max-width: 1062px) {
    margin-right: 20px;
  }

  @media (max-width: 1062px) {
    min-width: 32.5%;
    margin-right: 0px;
  }
  @media (max-width: 789px) {
    min-width: 45%;
  }
  @media (max-width: 768px) {
    margin-right: 0px;
    width: 48%;
  }

  @media (max-width: 612px) {
    width: 49%;
  }
  @media (max-width: 599px) {
    width: 100%;
  }
`;

const ClientDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  margin-left: 30px;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 50%;
  @media (max-width: 1000px) {
    width: 50%;
  }
`;

const ImgContainer = styled.div`
  img {
    width: 30%;
    float: right;
    margin-right: 13px;
  }
`;

const Title = styled.span`
  min-height: 56px;
  font-size: 20px;
  line-height: 140%;
  font-weight: bolder;
  letter-spacing: -0.09px;

  color: #333333;

  opacity: 0.8;
`;

const SubTitle = styled.span`
  font-size: 14px;
  line-height: 140%;

  letter-spacing: -0.09px;
  text-decoration-line: underline;

  color: #333333;
  margin-top: 16px;
`;

const DescContainer = styled.div`
  margin-top: -52px;
  margin-left: 29px;

  @media (max-width: 1312px) {
  }
`;

const PolygonContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 22px;
`;

const PolygonText = styled.span`
  margin-left: 5px;
`;

const ClientNumber = styled.div`
  text-align: center;
  padding: 20px 0;
  span {
    font-size: 50px;
    line-height: 140%;

    letter-spacing: -0.09px;

    color: #333333;
  }
`;

function DetailCard(props) {     //{bgColor, name, icon, subTitle, url, totalClient, number, polygonText, polygonIcon, clientList, lastWeek}

  const DetailCardValue = () => {
    if (props.name === 'Total Clients') {
      return props.totalClient;
    } else {
      return (props.clientList?.length != undefined ? props.clientList?.length : 0);
    }
  }

  const addClient = () => {
    sessionStorage.setItem('clientId', '');
    sessionStorage.setItem('viewMode', '');
    sessionStorage.setItem('peer_support_specialist', '')
    sessionStorage.setItem('intake_date', '')
    sessionStorage.setItem('dob', '')
    sessionStorage.setItem('month_1_followup_date', '')
    sessionStorage.setItem('email', '')
    sessionStorage.setItem('phone', '')
    sessionStorage.setItem('last_updated_by', '')
    // console.log("handleClientID : " , sessionStorage.getItem('last_updated_by'));
    sessionStorage.setItem('formname', '')
    sessionStorage.setItem('responceId', '')
    sessionStorage.setItem('client_first_name', '')
    sessionStorage.setItem('client_middle_initial', '')
    sessionStorage.setItem('client_last_name', '')
    sessionStorage.setItem('ssn', '');
    sessionStorage.setItem('last_updated_by', '');
    // console.log("handleClientID : " , sessionStorage.getItem('last_updated_by'));
    sessionStorage.setItem('Secondary_phone', '');
    sessionStorage.setItem('Leave_msg_pri', '');
    sessionStorage.setItem('Leave_msg_sec', '');
    sessionStorage.setItem('Type_lns', '');
    sessionStorage.setItem('Address_lns', '');

    sessionStorage.setItem('client_status', '')
    sessionStorage.removeItem('client_search_filter')

    setTimeout(() => window.location.href = "/intake-basicinfo", 500);
  }

  return (
    <MainContainer bgColor={props.bgColor}>
      <ClientDetailContainer>
        <HeaderContainer>
          <TitleContainer>
            <Title>{props.name}</Title>
            {/* {name == "Total Clients" && <a onClick={addClient} style={{cursor: 'pointer'}}><SubTitle>{subTitle}</SubTitle></a>} */}
          </TitleContainer>
          <ImgContainer>
            <img src={props.icon} alt={props.name} />
          </ImgContainer>
        </HeaderContainer>
        <DescContainer>
          <ClientNumber>
            <span>{DetailCardValue()}</span>
          </ClientNumber>
          {props.name != 'Past Follow-ups' &&
            <PolygonContainer>
              <img src={props.polygonIcon} alt={props.polygonText} />
              <PolygonText>{props.lastWeek != undefined ? props.lastWeek : 0} last week</PolygonText>
            </PolygonContainer>
          }
        </DescContainer>
      </ClientDetailContainer>
    </MainContainer>
  );
}

export default DetailCard;
