import React, { useState,useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import TextAreaAutosize from 'react-textarea-autosize';

export default function Criteria_questions(props) {
  const [adultAssesmentData, setadultAssesmentData] = useState({
    spend_great_deal_of_time: "",
    spend_great_deal_of_time_explain: "",
    strong_desire_to_use_aod: "",
    strong_desire_to_use_aod_explain: "",
    neglected_important_social_obligations: "",
    neglected_important_social_obligations_explain: "",
    continue_to_use_despite_negative_consequences: "",
    continue_to_use_despite_negative_consequences_explain: "",
    affected_you_socially: "",
    affected_you_socially_explain: "",
    give_up_on_participate: "",
    give_up_on_participate_explain: "",
    placing_yourself_in_danger: "",
    placing_yourself_in_danger_explain: "",
    contribute_to_physical_problem: "",
    contribute_to_physical_problem_explain: "",
    feel_same_effect: "",
    feel_same_effect_explain: "",
  });
  const [isSaveAndComplete,setisSaveAndComplete]=useState(false)
 const [saved,setsaved]=useState(true);
  const withdrawalSymptomsOptions = [
    {
      label: "Agitated (fidget, pace, etc.)",
      value: "Agitated_(fidget_pace_etc)",
    },
    { label: "Anxiety", value: "Anxiety" },
    { label: "Diarrhea", value: "Diarrhea" },
    { label: "Fatigue", value: "Fatigue" },
    {
      label: "Feeling sad, tense, or angry",
      value: "Feeling_sad_tense_or_angry",
    },
    { label: "Fever", value: "Fever" },
    { label: "Hand Tremors", value: "Hand Tremors" },
    { label: "Increased appetite", value: "Increased_appetite" },
    { label: "Insomnia or Hypersomnia", value: "Insomnia_or_Hypersomnia" },
    { label: "Memory Loss", value: "Memory_Loss" },
    {
      label: "Move and talk slower than usual",
      value: "Move_and_talk_slower_ than_usual",
    },
    { label: "Muscle aches", value: "Muscle_aches" },
    { label: "Nausea / Vomiting", value: "Nausea_Vomiting" },
    { label: "Runny nose / watery eyes", value: "Runny_nose_watery_eyes" },
    {
      label: "See, feel, hear things that aren't there",
      value: "See_feel_hear_things_that_aren't_there",
    },
  ];
  const dataListBody = {
    apikey: sessionStorage.getItem("__TOKEN__"),
    client_id: sessionStorage.getItem("clientId"),
    form_type: props.form_type,
    uploaded_by:sessionStorage.getItem("username"),
  };
  useEffect(async () => {
    loadData();
  }, []);

  useEffect(()=>{
    setTimeout(()=>{
      props.childToParent(adultAssesmentData);
    },500)
  },[adultAssesmentData])
  const handleOnSubmit = async(e) => {
    e.preventDefault();

    let final_data = {
      adultAssesmentData,
      dataListBody: dataListBody,
    };
    console.log("Your final data is here ", final_data);
    let res = await axios.post(
      global.restApiURL + "customrest/saveAcuteIntoxication",
      JSON.stringify(final_data)
    );
    if (res.data.success == true) {
      loadData();
      toast.success('Data added successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'success_CcriteriaQuestion',
      });
    }

  };
  const handleOnChange = async(e) => {
    setsaved(false);
    setadultAssesmentData({ ...adultAssesmentData, [e.target.name]: e.target.value });
    if (e.target.name == "spend_great_deal_of_time" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["spend_great_deal_of_time_explain"]: "", ["spend_great_deal_of_time"]: e.target.value, });
    } else if (e.target.name == "strong_desire_to_use_aod" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["strong_desire_to_use_aod_explain"]: "", ["strong_desire_to_use_aod"]: e.target.value, });
    } else if (e.target.name == "neglected_important_social_obligations" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["neglected_important_social_obligations_explain"]: "", ["neglected_important_social_obligations"]: e.target.value, });
    } else if (e.target.name == "continue_to_use_despite_negative_consequences" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["continue_to_use_despite_negative_consequences_explain"]: "", ["continue_to_use_despite_negative_consequences"]: e.target.value, });
    } else if (e.target.name == "affected_you_socially" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["affected_you_socially_explain"]: "", ["affected_you_socially"]: e.target.value, });
    } else if (e.target.name == "give_up_on_participate" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["give_up_on_participate_explain"]: "", ["give_up_on_participate"]: e.target.value, });
    } else if (e.target.name == "placing_yourself_in_danger" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["placing_yourself_in_danger_explain"]: "", ["placing_yourself_in_danger"]: e.target.value, });
    } else if (e.target.name == "contribute_to_physical_problem" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["contribute_to_physical_problem_explain"]: "", ["contribute_to_physical_problem"]: e.target.value, });
    } else if (e.target.name == "feel_same_effect" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["feel_same_effect_explain"]: "", ["feel_same_effect"]: e.target.value, });
    } 

   
  };
  const loadData = async () => {

    let getFollowupListBody = {
      apikey: sessionStorage.getItem("__TOKEN__"),
      client_id: sessionStorage.getItem("clientId"),
      form_type: props.form_type,
    };
    let response = await axios.post(global.restApiURL + "customRest/getAcuteIntoxication",JSON.stringify(getFollowupListBody));
      setadultAssesmentData(response.data.data.adultAssesmentData);
      setsaved(true);

  };

  
  return (
    <React.Fragment>

      <form onSubmit={handleOnSubmit}>
        <>
          <div className="row marginBottom1">
            <div className="col-md-12">
              <p
                className="lead float-left"
                style={{
                  fontSize: "1.05rem",
                  lineHeight: "20px",
                  marginBottom: "1rem",
                }}
              >
                <b className="text-green">Criteria Questions</b>
              </p>
            </div>
           
           
          {/* Larger_amount_of_aod,Larger_amount_aod_explain, previous_effort_to_control_aod , previous_effort_to_cut_down_explain*/}
          </div>
         
          <div className="row marginBottom1">
            <div className="col-lg-4 col-md-8">
              <label>
                Do you spend a great deal of time in activities to obtain AOD
                and/or feeling its effects?
              </label>
            </div>
            <div className="col-lg-2 col-md-4">
              <select
                className="form-control"
                name="spend_great_deal_of_time"
                onChange={(e) => handleOnChange(e)}
                value={adultAssesmentData.spend_great_deal_of_time}
                disabled={isSaveAndComplete}
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            {adultAssesmentData.spend_great_deal_of_time  == "Yes" && (
              <>
                <div className="col-lg-6 col-md-12">
                  <TextAreaAutosize
                    minRows={(adultAssesmentData.spend_great_deal_of_time_explain?.length/48) + adultAssesmentData.spend_great_deal_of_time_explain?.split(/\r\n|\r|\n/).length}
                    style={{overflow:'hidden'}}
                    className="form-control"
                    placeholder="Please explain"
                    name="spend_great_deal_of_time_explain"
                    onChange={(e) => handleOnChange(e)}
                    value={adultAssesmentData.spend_great_deal_of_time_explain}
                    disabled={isSaveAndComplete}
                  />
                </div>
              </>
            )}
          </div>
          <div className="row marginBottom1">
            <div className="col-lg-4 col-md-8">
              <label>
                Have you had cravings or a strong desire to use AOD?
              </label>
            </div>
            <div className="col-lg-2 col-md-4">
              <select
                className="form-control"
                name="strong_desire_to_use_aod"
                onChange={(e) => handleOnChange(e)}
                value={adultAssesmentData.strong_desire_to_use_aod}
                disabled={isSaveAndComplete}
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            {adultAssesmentData.strong_desire_to_use_aod == "Yes" && (
              <>
                <div className="col-lg-6 col-md-12">
                  <TextAreaAutosize
                    className="form-control"
                    minRows={(adultAssesmentData.strong_desire_to_use_aod_explain?.length/48) + adultAssesmentData.strong_desire_to_use_aod_explain?.split(/\r\n|\r|\n/).length}
                    style={{overflow:'hidden'}}
                    placeholder="Please explain"
                    name="strong_desire_to_use_aod_explain"
                    onChange={(e) => handleOnChange(e)}
                    value={adultAssesmentData.strong_desire_to_use_aod_explain}
                    disabled={isSaveAndComplete}
                  />
                </div>
              </>
            )}
          </div>
          <div className="row marginBottom1">
            <div className="col-lg-4 col-md-8">
              <label>
                Have you used AOD to the point that you have neglected important
                social obligations (work, school, home)?
              </label>
            </div>
            <div className="col-lg-2 col-md-4">
              <select
                className="form-control"
                name="neglected_important_social_obligations"
                onChange={(e) => handleOnChange(e)}
                value={adultAssesmentData.neglected_important_social_obligations}
                disabled={isSaveAndComplete}
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            {adultAssesmentData.neglected_important_social_obligations == "Yes" && (
              <>
                <div className="col-lg-6 col-md-12">
                  <TextAreaAutosize
                    minRows={(adultAssesmentData.neglected_important_social_obligations_explain?.length/48) + adultAssesmentData.neglected_important_social_obligations_explain?.split(/\r\n|\r|\n/).length}
                    style={{overflow:'hidden'}}
                    className="form-control"
                    placeholder="Please explain"
                    name="neglected_important_social_obligations_explain"
                    onChange={(e) => handleOnChange(e)}
                    value={adultAssesmentData.neglected_important_social_obligations_explain}
                    disabled={isSaveAndComplete}
                  />
                </div>
              </>
            )}
          </div>
          <div className="row marginBottom1">
            <div className="col-lg-4 col-md-8">
              <label>
                Have you continued to use despite the negative consequences (at
                work, school, home)?
              </label>
            </div>
            <div className="col-lg-2 col-md-4">
              <select
                className="form-control"
                name="continue_to_use_despite_negative_consequences"
                onChange={(e) => handleOnChange(e)}
                value={adultAssesmentData.continue_to_use_despite_negative_consequences}
                disabled={isSaveAndComplete}
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            {adultAssesmentData.continue_to_use_despite_negative_consequences == "Yes" && (
              <>
                <div className="col-lg-6 col-md-12">
                  <TextAreaAutosize
                    minRows={(adultAssesmentData.continue_to_use_despite_negative_consequences_explain?.length/48) + adultAssesmentData.continue_to_use_despite_negative_consequences_explain?.split(/\r\n|\r|\n/).length}
                    style={{overflow:'hidden'}}
                    className="form-control"
                    placeholder="Please explain"
                    name="continue_to_use_despite_negative_consequences_explain"
                    onChange={(e) => handleOnChange(e)}
                    value={adultAssesmentData.continue_to_use_despite_negative_consequences_explain}
                    disabled={isSaveAndComplete}
                  />
                </div>
              </>
            )}
          </div>
         
          <div className="row marginBottom1">
            <div className="col-lg-4 col-md-8">
              <label>
                Have you continued using AOD even though your use affected you
                socially (fights, problems in relationships, etc.)?
              </label>
            </div>
            <div className="col-lg-2 col-md-4">
              <select
                className="form-control"
                name="affected_you_socially"
                onChange={(e) => handleOnChange(e)}
                value={adultAssesmentData.affected_you_socially}
                disabled={isSaveAndComplete}

              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            {adultAssesmentData.affected_you_socially  == "Yes" && (
              <>
                <div className="col-lg-6 col-md-12">
                  <TextAreaAutosize
                    minRows={(adultAssesmentData.affected_you_socially_explain?.length/48) + adultAssesmentData.affected_you_socially_explain?.split(/\r\n|\r|\n/).length}
                    style={{overflow:'hidden'}}
                    className="form-control"
                    placeholder="Please explain"
                    name="affected_you_socially_explain"
                    onChange={(e) => handleOnChange(e)}
                    value={adultAssesmentData.affected_you_socially_explain}
                    disabled={isSaveAndComplete}

                  />
                </div>
              </>
            )}
          </div>
          <div className="row marginBottom1">
            <div className="col-lg-4 col-md-8">
              <label>
                Has your AOD use caused you to give up or not participate in
                social, occupational or recreational activities that you once
                enjoyed?
              </label>
            </div>
            <div className="col-lg-2 col-md-4">
              <select
                className="form-control"
                name="give_up_on_participate"
                onChange={(e) => handleOnChange(e)}
                value={adultAssesmentData.give_up_on_participate}
                disabled={isSaveAndComplete}
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            {adultAssesmentData.give_up_on_participate  == "Yes" && (
              <>
                <div className="col-lg-6 col-md-12">
                  <TextAreaAutosize
                    minRows={(adultAssesmentData.give_up_on_participate_explain?.length/48) + adultAssesmentData.give_up_on_participate_explain?.split(/\r\n|\r|\n/).length}
                    style={{overflow:'hidden'}}
                    className="form-control"
                    placeholder="Please explain"
                    name="give_up_on_participate_explain"
                    onChange={(e) => handleOnChange(e)}
                    value={adultAssesmentData.give_up_on_participate_explain}
                    disabled={isSaveAndComplete}
                  />
                </div>
              </>
            )}
          </div>
         
          <div className="row marginBottom1">
            <div className="col-lg-4 col-md-8">
              <label>
                Have you continued to use despite placing yourself and/or others
                in dangerous or unsafe situations?
              </label>
            </div>
           
            <div className="col-lg-2 col-md-4">
              <select
                className="form-control"
                name="placing_yourself_in_danger"
                onChange={(e) => handleOnChange(e)}
                value={adultAssesmentData.placing_yourself_in_danger}
                disabled={isSaveAndComplete}
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            {adultAssesmentData.placing_yourself_in_danger == "Yes" && (
              <>
                <div className="col-lg-6 col-md-12">
                  <TextAreaAutosize
                    minRows={(adultAssesmentData.placing_yourself_in_danger_explain?.length/48) + adultAssesmentData.placing_yourself_in_danger_explain?.split(/\r\n|\r|\n/).length}
                    style={{overflow:'hidden'}}
                    className="form-control"
                    placeholder="Please explain"
                    onChange={(e) => handleOnChange(e)}
                    name="placing_yourself_in_danger_explain"
                    value={adultAssesmentData.placing_yourself_in_danger_explain}
                    disabled={isSaveAndComplete}
                  />
                </div>
              </>
            )}
          </div>
          <div className="row marginBottom1">
            <div className="col-lg-4 col-md-8">
              <label>
                Have you continued to use AOD after knowing it caused or
                contributed to physical and/or psychological problems?
              </label>
            </div>
            <div className="col-lg-2 col-md-4">
              <select
                className="form-control"
                name="contribute_to_physical_problem"
                onChange={(e) => handleOnChange(e)}
                value={adultAssesmentData.contribute_to_physical_problem}
                disabled={isSaveAndComplete}
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            {adultAssesmentData.contribute_to_physical_problem == "Yes" && (
              <>
                <div className="col-lg-6 col-md-12">
                  <TextAreaAutosize
                    minRows={(adultAssesmentData.contribute_to_physical_problem_explain?.length/48) + adultAssesmentData.contribute_to_physical_problem_explain?.split(/\r\n|\r|\n/).length}
                    style={{overflow:'hidden'}}
                    className="form-control"
                    placeholder="Please explain"
                    onChange={(e) => handleOnChange(e)}
                    name="contribute_to_physical_problem_explain"
                    value={adultAssesmentData.contribute_to_physical_problem_explain}
                    disabled={isSaveAndComplete}
                  />
                </div>
              </>
            )}
          </div>
          <div className="row marginBottom1">
            <div className="col-lg-4 col-md-8">
              <label>
                Do you need more AOD to get the same high or feel the same
                effect?{" "}
              </label>
            </div>
            <div className="col-lg-2 col-md-4">
              <select
                className="form-control"
                name="feel_same_effect"
                onChange={(e) => handleOnChange(e)}
                value={adultAssesmentData.feel_same_effect}
                disabled={isSaveAndComplete}
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            {adultAssesmentData.feel_same_effect == "Yes" && (
              <>
                <div className="col-lg-6 col-md-12">
                  <TextAreaAutosize
                    minRows={(adultAssesmentData.feel_same_effect_explain?.length/48) + adultAssesmentData.feel_same_effect_explain?.split(/\r\n|\r|\n/).length}
                    style={{overflow:'hidden'}}
                    className="form-control"
                    placeholder="Please explain"
                    onChange={(e) => handleOnChange(e)}
                    name="feel_same_effect_explain"
                    value={adultAssesmentData.feel_same_effect_explain}
                    disabled={isSaveAndComplete}
                  />
                </div>
              </>
            )}
          </div>
        </>
        <div className="row">
          <div className="col-md-12">
            {/* <button className='btn btn-success float-right'><i className="fas fa-save"></i> Save</button> */}
            <button
              type="submit"
              data-save="save"
              disabled={saved}
              value="save"
              className="btn btn-success float-right btnmargin"
            >
              {" "}
              <i className="fas fa-save"></i> Save
            </button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}
