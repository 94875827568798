import React from "react";

import styled from "styled-components";
import GeneralDisSummary from "./GeneralDisSummary";
import ClientsStackBar from "./ClientsStackBar";
import GeneralDisByRace from "./GeneralDisByRace";
import CoverSourcesTable from "./CoverSourcesTable";
import CoverSourcesTable1 from "./CoverSourcesTable1";
import Topnav from "../Topnav";
import axios from "axios";
import { createHashHistory } from "history";
import { API_URL } from "../../commonFunctions/ApiUrl";


import PrintReportIcon from "../../assests/images/icon-print.png";
import { HelpBlock } from "react-bootstrap";
import '../../../src/App.css';


const MainContainer = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;
  background-color: #f9f9f9;
  overflow : hidden;
`;

const Header = styled.div`
  width: 100%;
`;


const PrintReport = styled.div`
  width: 100%;
  padding:0px 0px 0px 15px;
  display: flex;
  justify-content: end;
  align-items: center;
  height: 40px;

  padding-right: 16px;
  background-color: #efefef;
  span {
    color: #7bb731;
    margin-left: 8px;
  }
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const PageHeading = styled.div`
  width: 100%;
  padding:10px 0px 0px 0px;
  display: flex;
  justify-content: Center;
  align-items: center;
  height: 40px;

  padding-right: 16px;
  background-color: #efefef;
  span {
    color: #7bb731;
    margin-left: 8px;
  }
  // img {
  //   width: 20px;
  //   height: 20px;
  //   cursor: pointer;
  // }
`;



// const PrintReport = styled.div`
  
  
//   align-items: center;
//   height: 40px;

//   padding-right: 16px;
//   background-color: #efefef;
//   span {
//     color: #7bb731;
//     margin-left: 8px;
//   }
//   img {
//     width: 20px;
//     height: 20px;
//     cursor: pointer;
//   }
// `;


const FirstChart = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  padding: 0 16px 0 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const Title = styled.span`
  flex: 0 0 40%;
  @media (max-width: 1370px) {
    flex: 0 0 30%;
  }
`;

const FilterContainer = styled.div`

  width: 120px;
  display: flex;
  flex: 0 0 60%;
  justify-content: space-between;
  @media (max-width: 1370px) {
    flex: 0 0 70%;
  }
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #7bb731;
  color: #fff;
  border-radius: 4px;
  width: 120px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterDate = styled.div`
  width: 150px;
  border: 1px solid #cbcbcb;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  justify-content: center;
`;


const PageHeadingStyle=styled.div`
    margin:"0px auto";
    display:"block";
    width:100%;
    background-color:#efefef;
   
`;


const printreportPageContainer=styled.div`
    margin:"0px auto";
    display:"block";
    background-color:#efefef;
    width:100% !important;
  
   
`;



function CoverReport() {

  
  return (
    <MainContainer>
      {/* <Header>
        <Topnav dropdown={true} />
      </Header>
     
      
      <PageHeading>
      <h3 style={{color:"#89BC3B"}}>Cover Report</h3>

      </PageHeading>

      <PrintReport  >

         <div style={{cursor:'pointer'}} onClick={() => window.print()} >
          <img src={PrintReportIcon}/>
          <span>Print Report</span>
        </div>

      
      </PrintReport> */}
      <Header>
        <Topnav dropdown={true} />
      </Header>
      

  
       <PageHeadingStyle>
       <h3 className="py-2" style={{color:"#89BC3B",textAlign:"center"}}>Cover Report</h3>
       </PageHeadingStyle>


       <printreportPageContainer style={{width:"100%"}}>
        <div className="py-2" style={{color:"#89BC3B",textAlign:"right",display:"inlineBlock"}} >
          <img className="mr-2" style={{cursor:"pointer"}} src={PrintReportIcon} alt="print" onClick={() => window.print()} />
            <span className="mr-3" style={{cursor:"pointer"}} onClick={() => window.print()}>Print Report</span>
       </div>
       </printreportPageContainer>





      <FirstChart>
      <GeneralDisSummary  addToDashBoard={true} /> 
      <ClientsStackBar  addToDashBoard={true} />
      </FirstChart>


      <GeneralDisByRace addToDashBoard={true} isHome={false} />
      
      <CoverSourcesTable
        title="Clients"
        filter="All Clients"
        filterOptions={["Gender", "Race", "Ethnicity"]}
        />
      <CoverSourcesTable1
        title="Clients by Peer Support Specialist"
        filter="All Clients"
        filterOptions={["Peer Specialist", "Intake Per County"]}
      />
     
    </MainContainer>
  );
}

export default CoverReport;
