import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import { BidirectionalBar } from "@ant-design/charts";

import axios from "axios";
import { createHashHistory } from "history";
import { API_URL } from "../../commonFunctions/ApiUrl";

import "../../../src/index.css";
import Chart from "react-apexcharts";
const MainContainer = styled.section`
  width: 45%;
  margin-left: 6px;
  background-color: #fff;

`;

// const series = [
//   {
//     name: "Males",
//     data: [-71, -58, -32, -51, -62, -64],
//   },
//   {
//     name: "Females",

//     data: [31, 39, 13, 43, 33, 27],
//   },
// ];

// const series = [
//   {
//     "name": "Males",
//     "data": [
//       {
//         "val": "-71"
//       },
//       {
//         "val": "-58"
//       },
//       {
//         "val": "-32"
//       },
//       {
//         "val": "-62"
//       },
//       {
//         "val": "-64"
//       }
//     ]
//   }
// ];

function GDByRace(props) {
  const catArray = props.categories;
  const seriesBar = props.seriesBar;

  // console.log("seriesBar");
  // console.log(seriesBar);
  const [getGdByRace, setgetGdByRace] = useState([]);

  const [getSeriesbar, setSeriesbar] = useState([]);
  // const [getCategories, setCategories] = useState([]);

  // useEffect((year) => {
  //   fetchGdByRace(year);
  // }, []);

  // var options = {
  //   dataLabels: {
  //       enabled: true,
  //       fontSize: "140px",
  //       style: {
  //         fontSize: "140px",
  //         fontFamily: "Helvetica, Arial, sans-serif",
  //         fontWeight: "bold"
  //       }
  //     },
  //   chart: {
  //     type: "bar",
  //     height: 300,
  //     stacked: true,

  //     toolbar: {
  //       show: false,

  //     },
  //   },
  //   colors: ["#1c6ce6", "#F6D0F0"],

  //   plotOptions: {
  //     bar: {
  //       dataLabels: {
  //         position: "top",
  //       },
  //       horizontal: true,
  //       barHeight: "80%",
  //     },
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     offsetX: -10,
  //     style: {
  //       colors: ["#333"],
  //     },
  //     formatter: function (val) {
  //       return Math.abs(val) + "%";
  //     },
  //   },
  //   stroke: {
  //     width: 1,
  //     colors: ["#fff"],
  //   },

  //   grid: {
  //     xaxis: {
  //       lines: {
  //         show: false,
  //       },
  //     },
  //   },
  //   yaxis: {
  //     min: -100,
  //     max: 100,
  //     labels: {
  //       show: true,
  //       align: "left",
  //       marginLeft: "20px",

  //     },
  //     marginLeft: 100,

  //   },

  //   xaxis: {
  //     // categories: [
  //     //   "American Indian",
  //     //   "Asian",
  //     //   "Black",
  //     //   "Native Hawaiian",
  //     //   "White",
  //     //   "Blank",
  //     // ],
  //     categories: catArray,

  //     position: "top",
  //     labels: {
  //       show: false,
  //     },
  //   },
  //   legend: {
  //     show: true,
  //     horizontalAlign: "center",
  //     position: "top",

  //     onItemHover: {
  //       highlightDataSeries: false,
  //     },
  //     onItemClick: {
  //       toggleDataSeries: false,
  //     },
  //   },
  // };

  const series = [
    {
      name: "Male",
      data: [0, 0, 0, 0, 1, 0],
    },
    {
      name: "Female",
      data: [0, 0, 0, 0, 2, 0],
    },
    {
      name: "Trans",
      data: [0, 0, 1, 0, 0, 0],
    },
    {
      name: "Blank",
      data: [0, 0, 0, 0, 0, 0],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 300,
      stacked: true,
      stackType: "100%",
    },
    colors: ["#1c6ce6", "#F6D0F0", "#9afce1", "#000000"],
    plotOptions: {
     
      bar: {
        dataLabels: {
          position: "top",
        },
        horizontal: true,
        barHeight: "80%",
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -20,
      style: {
        colors: ["#FFFFFF"],
      },
      formatter: function (val) {
        // return Math.abs(val) + "%";
        return Math.round(Math.abs(val) * 10) / 10 + "%";
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },

    xaxis: {
      min: 0,
      max: 100,
      // categories: [2008, 2009, 2010, 2011, 2012, 2013, 2014],
      categories: catArray,
      labels: {
        show: false,
      },
    },

    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      offsetX: 40,
      onItemHover: {
        highlightDataSeries: false,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
  };

  return (
    <MainContainer>
      {/* <Chart
        options={options}
        series={seriesBar}
        type="bar"
        width="90%"
        height="300px"
      /> */}
      <Chart options={options} series={seriesBar} type="bar" height={300} />
    </MainContainer>
  );
}

export default GDByRace;
