import React, { useState, useEffect } from "react";
import styled from "styled-components";
import GeneralDisSummary from "./GeneralDisSummary";

import ClientsStackBar from "./ClientsStackBar";
import ReferralSource from "./ReferralSource";
import ReferralSource_New from "./ReferralSource_New";

import TotalReferrals from "./TotalReferrals";

import GeneralDisByRace from "./GeneralDisByRace";

import FollowupAttempPieChart from "./FollowupAttempPieChart";

import OutcomesStackBar from "./OutcomesStackBar";

// import GeneralDisByRace from "./GeneralDisByRace";

import Topnav from "../Topnav";

import Filter from "./Filter";

const MainContainer = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
  background-color: #f9f9f9;
`;

const Header = styled.div`
  width: 100%;
 
`;

const DashLetsContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 16px;
  height: 50px;
`;
const FirstChart = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.direction ? "row-reverse" : "row")};
  width: 100%;

  padding: 0 16px 0 16px;
`;

const SecondChart = styled(FirstChart)`
  flex-direction: ${(props) => (props.direction ? "row-reverse" : "row")};
`;

const ThirdChart = styled(FirstChart)`
  flex-direction: row;
`;

const dashletsOptions = [
  "Gender Distribution Summary",
  "Gender Distribution by Race",
  "Clients",
  "Total Referrals",
  "Referrals by Source",
];

const AdmhChart=styled.div`
display: flex;
justify-content: space-between;
flex-direction: ${(props) => (props.direction ? "row-reverse" : "row")};
width: 100%;

padding: 0 16px 0 16px;
`;

function Charts() {
  const [directionFirst, setDirectionFirst] = useState(false);
  const [directionSecond, setDirectionSecond] = useState(false);

  const generalDis = sessionStorage.getItem("generalDis");
  const stackBar = sessionStorage.getItem("stackBar");
  const RefSource = sessionStorage.getItem("RefSource");
  const TotReferrals = sessionStorage.getItem("TotReferrals");
  const GenDisByRace = sessionStorage.getItem("generalDis");
  const FollowUpAttemps = sessionStorage.getItem("FollowUpAttemps");
  const OutcomeStackBar = sessionStorage.getItem("OutcomeStackBar");


  // console.log("RefSource");
  // console.log(RefSource);
  //   const DashboardHeading = styled.div`
  //   width: 100%;
  //   padding:0px 0px 0px 15px;
  //   display: flex;
  //   justify-content: space-between;referral_report
  //   align-items: center;
  //   height: 60px;

  //   padding-right: 16px;
  //   background-color: #efefef;

  // `;

  // Code By ashraf Start

  const PageHeading = styled.div`
    width: 100%;
    padding: 30px 0px 30px 0px;
    display: flex;
    justify-content: Center;
    align-items: center;
    height: 40px;

    padding-right: 16px;
    background-color: #efefef;
    span {
      color: #7bb731;
      margin-left: 8px;
    }
  `;

  // Code By ashraf End
  return (
    <MainContainer>
      <Header>
        <Topnav dropdown={true} />
      </Header>
      {/* dashBoard Heading */}
      <PageHeading>
        <h3 style={{ color: "#89BC3B" }}>Dashboard</h3>
      </PageHeading>

     
  
     {GenDisByRace? <GeneralDisByRace addToDashBoard={false}  isActionIcon={true} />:" "
}



      <FirstChart direction={directionFirst}>
        {generalDis ? (
          <GeneralDisSummary setDirection={setDirectionFirst} isActionIcon={true} />
        ) : (
          ""
        )}
     
        {stackBar ? <ClientsStackBar  setDirection={setDirectionFirst} isActionIcon={true}  /> : ""}
      </FirstChart>



      <SecondChart direction={directionSecond}>
        {RefSource ?  <ReferralSource_New setDirection={setDirectionSecond} addToDashBoard={false} isActionIcon={true}  /> : " "}
        {/* <ReferralSource_New setDirection={setDirectionSecond} addToDashBoard={false} isActionIcon={true}  />  */}
        {TotReferrals ? (
          <TotalReferrals setDirection={setDirectionSecond} />
        ) : (
          ""
        )}

      </SecondChart>
    



      
{/* <div className="row">
  <div className="col-4"> */}
  {/* <FirstChart>
  {FollowUpAttemps ? (
              <FollowupAttempPieChart  />
            ) : (
              ""
            )}
  </FirstChart>
   */}
  {/* </div>
  <div className="col-8"></div>
// </div> */}
     
      <FirstChart direction={directionFirst}>
        {FollowUpAttemps ? (
          <FollowupAttempPieChart setDirection={setDirectionFirst} isActionIcon={true} />
        ) : (
          ""
        )}
     
        {OutcomeStackBar ? <OutcomesStackBar  setDirection={setDirectionFirst}  isActionIcon={true} /> : ""}
      </FirstChart>

    </MainContainer>
  );
}

export default Charts;
