import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Tooltip from '../ReusableComponents/Tooltip';

export default class Client_characteristics extends Component {
    constructor(props){
        super(props);
        this.state = {
            maritial_status : '',
            employment_status : '',
            educational_status : '',
            hearing_status : '',
            language_preference : '',
            linguistic_status : '',
            indicate_living_arrangement : '',

            saved:true,
            isSaveAndComplete:false,
        }
    }
    dataListBody= {
        apikey: sessionStorage.getItem("__TOKEN__"),
        client_id: sessionStorage.getItem("clientId"),
        form_type:this.props.form_type,
        uploaded_by:sessionStorage.getItem("username"),
      }

     linguisticOption = [
        { value: "", label: "Select" },
        { value: "cognitive_disability", label: "Cognitive Disability" },
        { value: "english_proficiency", label: "English Proficiency" },
        { value: "limited_english_proficiency", label: "Limited English Proficiency" },
        { value: "low_literacy_level", label: "Low Literacy Level" },
        { value: "not_literate", label: "Not Literate" },
        { value: "other_disability", label: "Other Disability" },
      ];


    handleChange = async(e)=>{
        this.setState({[e.target.name]: e.target.value,["saved"]:false});

        setTimeout(()=>{
            this.props.childToParent(this.state);
          },500)
    }
    handleOnSubmit=async(e)=>{
        e.preventDefault();
        let final_data = {
            ClientCharacteristicsData: this.state,
          dataListBody: this.dataListBody,
        };
        let res = await axios.post(
            global.restApiURL + "customrest/updateClientCharacterstics",
            JSON.stringify(final_data)
          );
          if (res.data.success == true) {
            this.loadData();
            toast.success('Data added successfully!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
	      toastId: 'success_ClientCharacterstics',
            });
          }
    }

     loadData = async () => {

            let getFollowupListBody = {
            apikey: sessionStorage.getItem("__TOKEN__"),
            client_id: sessionStorage.getItem("clientId"),
            form_type:this.props.form_type,
            };
            let res = await axios.post(
            global.restApiURL + "customRest/getUpdatedClientCharacterstics",
            JSON.stringify(getFollowupListBody)
            );
            if(res.data.success==true){
                this.setState({
                    maritial_status : res.data.data.ClientCharacteristicsData.maritial_status,
                    employment_status : res.data.data.ClientCharacteristicsData.employment_status,
                    educational_status : res.data.data.ClientCharacteristicsData.educational_status,
                    hearing_status : res.data.data.ClientCharacteristicsData.hearing_status,
                    language_preference : res.data.data.ClientCharacteristicsData.language_preference,
                    linguistic_status : res.data.data.ClientCharacteristicsData.linguistic_status,
                    indicate_living_arrangement : res.data.data.ClientCharacteristicsData.indicate_living_arrangement,
                    saved :true,
                })
            }

            setTimeout(()=>{
                this.props.childToParent(this.state);
              },500)

    
      };

      componentDidMount(){
        this.loadData();
      }
    render() {
        return (
            <React.Fragment>
                   <form onSubmit={this.handleOnSubmit}>
                <div className='row'>
                    <div className='col-md-12'>
                        <p className="lead float-left" style={{fontSize: '1.05rem', lineHeight: '20px',marginBottom: '1rem'}}><b className="text-green">Client Characteristics and Services</b></p>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='form-group'>
                            <label>Marital Status</label>
                            <select className='form-control' disabled={this.state.isSaveAndComplete} value={this.state.maritial_status} name="maritial_status" onChange={this.handleChange}>
                                <option value="">Select</option>
                                <option value="Common Law">Common Law </option>
                                <option value="Single">Single</option>
                                <option value="Living Together">Living Together</option>
                                <option value="Married">Married</option>
                                <option value="Separated">Separated</option>
                                <option value="Divorced">Divorced</option>
                                <option value="Widowed">Widowed</option>

                            </select>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='form-group'>
                            <label>Employment Status</label>
                            <select className='form-control' disabled={this.state.isSaveAndComplete} name="employment_status" value={this.state.employment_status} onChange={this.handleChange}>
                                <option value="">Select</option>
                                <option value="confined_to_institution_correctional_facility">Confined to Institution/Correctional Facility</option>
                                  <option value="disabled">Disabled</option>
                                  <option value="full_time">Full-time</option>
                                  <option value="homemaker">Homemaker</option>
                                  <option value="part_time">Part-time</option>
                                  <option value="retired">Retired</option>
                                  <option value="student">Student</option>
                                  <option value="supported_employment">Supported Employment</option>
                                  <option value="unemployed_looking">Unemployed,looking</option>
                                  <option value="unemployed_not_looking">Unemployed, not looking for 30 days</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='form-group'>
                            <label>Education</label>
                            <select className='form-control' disabled={this.state.isSaveAndComplete} name="educational_status" value={this.state.educational_status} onChange={this.handleChange}>
                                <option value="">Select</option>
                                    <option value="none">None</option>
                                    <option value="student">Student</option>
                                    <option value="graduated_hs">Graduated HS</option>
                                    <option value="ged">GED</option>
                                    <option value="dropped_out">Dropped Out</option>
                                    <option value="college_graduate">College Graduate</option>
                                    <option value="some_education_after_hs">Some education after HS</option>
                                    <option value="other">Other</option>

                            </select>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='form-group'>
                            <label>Hearing Status</label>
                            <select className='form-control' disabled={this.state.isSaveAndComplete} name="hearing_status" value={this.state.hearing_status}  onChange={this.handleChange}>
                                <option value="">Select</option>
                                <option value="hearing">Hearing</option>
                                <option value="hard_of_hearing">Hard of Hearing</option>
                                <option value="deaf">Deaf</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='form-group'>
                            <label>Language Preference</label>
                            <select className='form-control' disabled={this.state.isSaveAndComplete} name="language_preference" value={this.state.language_preference}  onChange={this.handleChange}>
                                <option value="">Select</option>
                                <option value="english">English</option>
                                <option value="american_sign_language">American Sign Language</option>
                                <option value="arabic">Arabic</option>
                                <option value="chinese">Chinese</option>
                                <option value="french_and_french_creole">French And French Creole(Including Cajun)</option>
                                <option value="german">German</option>
                                <option value="hindi">Hindi</option>
                                <option value="italian">Italian</option>
                                <option value="japanese">Japanese</option>
                                <option value="korean">Korean</option>
                                <option value="laotian">Laotian</option>
                                <option value="other_african_languages">Other African Languages</option>
                                <option value="other_asian_languages">Other Asian Languages</option>
                                <option value="other_europian_languages">Other Europian Languages</option>
                                <option value="other_indic_languages">Other Indic Languages</option>
                                <option value="persian">Persian</option>
                                <option value="portuguese">Portuguese</option>
                                <option value="russian">Russian</option>
                                <option value="spanish_and_spanish_creole">Spanish And Spanish Creole</option>
                                <option value="tagalog">Tagalog</option>
                                <option value="vietnamese">Vietnamese</option>
                               

                                
                                    
                                    
                                
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='form-group'>
                            <label>Linguistic Status</label>
                            <select className='form-control' disabled={this.state.isSaveAndComplete} name="linguistic_status"  value={this.state.linguistic_status} onChange={this.handleChange}>
                            {this.linguisticOption.map((linguistic) => (
                                        <option value={linguistic.value}>
                                          {linguistic.label}
                                        </option>
                                      ))}
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='form-group'>
                            <label>Living Arrangements <Tooltip name="indicate_living_arrangement" text="(Independent living is for adults age 18+ (lives alone OR Resides with family (18+). Resides with Family is ONLY a choice for clients 17 and under)" /></label>
                            <select className='form-control' disabled={this.state.isSaveAndComplete} name="indicate_living_arrangement"  value={this.state.indicate_living_arrangement} onChange={this.handleChange}>
                                <option value="">Select</option>
                                <option value="alabama_housing_finance_authority">Alabama Housing Authority</option>
                                <option value="center_residential_program">Center Operated/Contracted Residential Program</option>
                                <option value="homeless_shelter">Homeless Shelter</option>
                                {/* {this.props.age > 18 ? <option value="Independent lives alone OR resides w/Family and is 18+ (Independent)">Independent lives alone OR resides w/Family and is 18+ (Independent)</option> : <option value="Independent lives alone OR resides w/Family">Independent lives alone OR resides w/Family</option>} */}
                                {this.props.form_type=="Adult_updateAdmh" ? <option value="Client_lives_alone"> Client Lives Alone AND is 18 + (Independent)</option> : <option value="resides_with_family">Resides with Family</option>}
                                <option value="jail_correctional_facility">Jail/Correctional Facility</option>
                                <option value="group_home_or_residential_setting">Group Home or Residential Setting</option>
                                <option value="center_subsidized_housing">Center Subsidized Housing</option>
                                <option value="other">Other</option>

                              
                               
                            
                            </select>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button type="submit" data-save="save" disabled={this.state.saved} value='save' className="btn btn-success float-right btnmargin"> <i className="fas fa-save"></i> Save</button>
                    </div>
                </div>
                </form>
            </React.Fragment>
        )
    }
}
